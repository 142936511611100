import React from "react";
import "../../assets/css/term.css";

function Term() {
  return (
    <>
      <section id="terms-of-service">
        <div className="card">
          <h1 className="primary-heading">Terms of Service</h1>
          <h5>Introduction.</h5>
          <p>
            Welcome to www.looktaza.com. These are the terms and conditions
            governing your use of the Site ("herein after referred to as
            Acceptable Use Policy "AUP"). By accessing Looktaza either through
            the website or any other electronic device, you acknowledge, accept
            and agree to the following terms of the AUP, which are designed to
            make sure that Looktaza works for everyone. This AUP is effective
            from the time you log in to Looktaza. By accepting this AUP, you are
            also accepting and agreeing to be bound by the Privacy Policy and
            the Listing Policy.
          </p>
          <p>
            Looktaza is platform where service provider and user come together.
            here user can get services easily and quickly which is provide by
            service provider. Looktaza do not interfiy on any kind of deal
            between service provider and user. If any service provider posting
            any kind of illigal post. then looktaza can block or remove that
            accout permanently.
          </p>
          <h6>While using Looktaza.</h6>
          <p>
            You agree and understand that www.looktaza.com is an internet
            enabled electronic platform that facilitates communication for the
            purposes of advertising and distributing information pertaining to
            goods and/ or services. You further agree and understand that we do
            not endorse, market or promote any of the listings, postings or
            information, nor do we at any point in time come into possession of
            or engage in the distribution of any of the goods and/ or services,
            you have posted, listed or provided information about on our site.
            While interacting with other users on our site, with respect to any
            listing, posting or information we strongly encourage you to
            exercise reasonable diligence as you would in traditional off line
            channels and practice judgment and common sense before committing to
            or complete intended sale, purchase of any goods or services or
            exchange of information. We recommend that you read our Safety tips
            before doing any activity on our site. While making use of looktaza
            classifieds and other services such as the discussion forums,
            comments and feedback and other services interalia, you will post in
            the appropriate category or area and you agree that your use of the
            Site shall be strictly governed by this AUP including the policy for
            listing of your Classified which shall not violate the prohibited
            and restricted items policy (herein after referred to as the Listing
            Policy.) (Listing Policy) The listing policy shall be read as part
            of this AUP and is incorporated in this AUP by way of reference:
          </p>
          <p>
            • " Your Information" is defined as any information you provide to
            us or other users of the Site during the registration, posting,
            listing or replying process of classifieds, in the feedback area (if
            any), through the discussion forums or in the course of using any
            other feature of the Services. You agree that you are the lawful
            owner having all rights, title and interest in your information, and
            further agree that you are solely responsible and accountable for
            Your Information and that we act as a mere platform for your online
            distribution and publication of Your Information.
          </p>
          <p>• You agree that your listing, posting and / or Information :</p>
          <p>
            - shall “not be fraudulent, misrepresent, mislead or pertain to the
            sale of any illegal, counterfeit, stolen goods and / or services
          </p>
          <p>
            - shall not pertain to good , services of which you are not the
            lawful owner or you do not have the authority or consent to 'list'
            which do not belong to you or you do not have the authority for.
          </p>
          <p>
            - Shall not belong to another person and to which the user does not
            have any right;
          </p>
          <p>
            - Shall not be defamatory, obscene, pornographic, pedophilic,
            invasive of another‘s privacy, including bodily privacy, insulting
            or harassing on the basis of gender, libellous, racially or
            ethnically objectionable, relating or encouraging money laundering
            or gambling, or otherwise inconsistent with or contrary to the laws
            in force;
          </p>
          <p>- Shall not be harmful to children;</p>
          <p>
            - Shall not infringe any patent, trademark, copyright or other
            proprietary rights;
          </p>
          <p>- Shall not violate any law for the time being in force;</p>
          <p>
            - Shall not deceive or mislead the addressee about the origin of the
            message or knowingly and intentionally communicates any information
            which is patently false or misleading in nature but may reasonably
            be perceived as a fact;
          </p>
          <p>- Shall not impersonate another person;</p>
          <p>
            - Shall not threaten the unity, integrity, defence, security or
            sovereignty of India, friendly relations with foreign States, or
            public order, or cause incitement to the commission of any
            cognisable offence or prevents investigation of any offence or is
            insulting other nation;
          </p>
          <p>
            - Shall not contain software virus or any other computer code, file
            or program designed to interrupt, destroy or limit the functionality
            of any computer resource;
          </p>
          <p>
            - Shall not be patently false and untrue, and written or published
            in any form, with the intent to mislead or harass a person, entity
            or agency for financial gain or to cause any injury to any person.
          </p>
          <p>- Shall not constitute an unfair trade practice</p>
          <p>
            - shall not distribute or contain spam, multiple / chain letters, or
            pyramid schemes in any of its forms.
          </p>
          <p>
            -shall not distribute viruses or any other technologies that may
            harm looktaza or the interests or property of looktaza users or
            impose an unreasonable load on the infrastructure or interfere with
            the proper working of looktaza.
          </p>
          <p>
            - shall not, directly or indirectly, offer, attempt to offer, trade
            or attempt to trade in any goods and services, the dealing of which
            is prohibited or restricted in any manner under the provisions of
            any applicable law, rule, regulation or guideline for the time being
            in force.
          </p>
          <p>
            - shall not be placed in a wrong category or in an incorrect area of
            the site.
          </p>
          <p>
            - shall not be placed in any other looktaza site except on the site
            that relates to the city in which you are located.
          </p>
          <p>
            - shall not list or post or pertain to information that is either
            prohibited or restricted under the laws of the Republic of India and
            such listing, posting or information shall not violate looktaza's
            Listing Policy.
          </p>
          <p>
            - You consent to receive communications by email, call or by such
            other mode of communication, electronic or otherwise related to the
            services provided through the website.
          </p>
          <p>
            You agree that your listing, posting and / or Information:if you use
            the Site by registering on the Site, you are responsible for
            maintaining the confidentiality of your User ID, password, email
            address and for restricting access to your computer, computer
            system, computer network and your looktaza account, and you are
            responsible for all activities that occur under your User ID and
            password. If you access the Site using any electronic device other
            than by registration on the Site, the AUP remains applicable to you
            in the same manner as if you are a registered user on the Site. You
            (the Seller) agree to provide the following information:.
          </p>
          <p>Contractual information pertaining to the goods, if any.</p>
          <p>
            Total price of any good or service, along with the breakup price for
            the good or service, showing all the compulsory and voluntary
            charges and the applicable tax.
          </p>
          <p>
            All mandatory notices and information provided by applicable laws,
            and the expiry date of the good being offered for sale, where
            applicable.
          </p>
          <p>
            All relevant details about the goods and services offered including
            country of origin
          </p>
          <p>
            The name and contact numbers, and designation of the grievance
            officer of the Seller
          </p>
          <p>
            Name and details of importer in case of imported goods, and
            guarantees related to the authenticity or genuineness of the
            imported products
          </p>
          <p>
            Terms of exchange, returns, and refund including information related
            to costs of return shipping.
          </p>
          <p>
            Relevant details related to delivery and shipment of such goods or
            services.
          </p>
          <p>
            Any relevant guarantees or warranties applicable to such goods or
            services.
          </p>
          <p>
            Rating/aggregated feedback The Sellers represent and warrant to
            looktaza that all the content pertaining to the goods/services by
            posted are accurate. Any advertisement. Post shall be consistent
            with the actual characteristics, access and usage conditions of such
            goods and services..
          </p>
          <h6>Eligibility</h6>
          <p>
            Use of www.looktaza.com,either by registration or by any other
            means, is available only to persons, who are Citizens of the
            Republic of India, who are 18 yrs of age and above and persons who
            can enter into a legally binding contract, and or are not barred by
            any law for the time being in force. If you access looktaza, either
            by registration on the Site or by any other means, not as an
            individual but on behalf of a legal entity, you represent that you
            are fully authorized to do so and the listing, posting or
            information placed on the site on behalf of the legal entity is your
            responsibility and you agree to be accountable for the same to other
            users of the Site.
          </p>
          <h6>Abuse of looktaza's Services</h6>
          <p>
            You agree to inform us if you come across any listing or posting
            that is offensive or violates our listing policy or infringes any
            intellectual property rights by clicking on the following link
            support@looktaza.com to enable us to keep the site working
            efficiently and in a safe manner. We reserve the right to take down
            any posting, listing or information and or limit or terminate our
            services and further take all reasonable technical and legal steps
            to prevent the misuse of the Site in keeping with the letter and
            spirit of this AUP and the listing policy. In the event you
            encounter any problems with the use of our site or services you are
            requested to report the problem by clicking on this link
            support@looktaza.com
          </p>
          <h6>Violations by User</h6>
          <p>
            You agree that in the event your listing, posting or your
            information violates any provision of this AUP or the listing
            policy, we shall have the right to disable the offending content,
            terminate and or suspend your membership of the Site and refuse to
            provide you or any person acting on your behalf, access to the Site.
          </p>
          <p>
            If any such content posted by you is disabled or removed, we shall
            provide you with a notification explaining the action being taken.
            You shall be given reasonable and adequate opportunity to dispute
            the action being taken and to request reinstatement of the access to
            such removed content. The Resident Grievance Officer (detailed in
            the parts below) shall be overseeing this process.
          </p>
          <p>
            Upon receiving actual knowledge in the form of an order by a court
            of competent jurisdiction or on being notified by the appropriate
            government or its agency, we shall not host, store or publish any
            unlawful information which is prohibited under any law for the time
            being in force in relation to the interest of sovereignty and
            integrity of India, security of the State, friendly relations with
            foreign states, public order, decency or morality, in relation to
            contempt of court, defamation, incitement to an offence relating to
            the above or any information which is prohibited under any law for
            the time being in force.
          </p>
          <p>
            If any such information is being hosted by us, we shall remove or
            disable access to that information no later than 36 hours from the
            receipt of the court order or on being notified by the Appropriate
            Government or its agency..
          </p>
          <p>
            We shall, within no later than 72 hours, also provide any
            information in our control or possession, to the appropriate
            government agency, for the purpose of verification of identity or
            for the prevention, detection, investigation or prosecution of
            offences under any law for the time being or for cyber security
            incidents..
          </p>
          {/* <p>
            Grievance Redressal Mechanism under the Information Technology
            (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021
          </p>
          <p>Name: Hanuman M K</p>
          <p>Designation: Grievance Officer</p>
          <p>Contact Details: grievanceofficer@looktaza.com</p> */}
          <p>
            Your complaint will be acknowledged by email within twenty four
            hours, providing you with a complaint number using which you can
            track the status of the complaint. We will dispose of such complaint
            within 15 days of receipt..
          </p>
          <p>
            We shall, within twenty-four hours from the receipt of a complaint
            made by an individual or any person on his/her behalf, in relation
            to any content which is prima facie in the nature of any material
            which exposes the private area of such individual, shows such
            individual in full or partial nudity or shows or depicts such
            individual in any sexual act or conduct, or is in the nature of
            impersonation in an electronic form, including artificially morphed
            images of such individual, take all reasonable and practicable
            measures to remove or disable access to such content which is
            hosted, stored, published or transmitted..
          </p>
          <p>
            Grievance Redressal Mechanism under the Consumer Protection
            (E-Commerce Rules), 2020
          </p>
          {/* <p>Name: Hanuman M K</p>
          <p>Designation: Grievance Officer</p>
          <p>Contact Details: grievanceofficer@looktaza.com</p> */}
          <p>
            Your complaint will be acknowledged by email within forty eight
            hours providing you with a ticket number using which you can track
            the status of the complaint. and redress the complaint within one
            month from the date of receipt of the Complaint..
          </p>
          <h6>Content</h6>
          <p>
            The Site contains content which includes Your Information,
            looktaza's information and information from other users. You agree
            not to copy, modify, or distribute such content (other than Your
            Information), looktaza's copyrights or trademarks. When you give us
            any content as part of Your Information, you are granting us a
            non-exclusive, worldwide, perpetual, irrevocable, royalty-free,
            sub-licensable right and license to use, reproduce, , publish,
            translate, distribute, perform and display such content (in whole or
            part) worldwide through the Site as well as on any of our affiliates
            or partners websites, publications and mobile platform. We need
            these rights with respect to the content in Your Information in
            order to host and display your content. If you believe that there is
            a violation, please notify us by clicking here support@looktaza.com.
          </p>
          <p>
            We reserve the right to remove any such content where we have
            grounds for suspecting the violation of these terms and our Listing
            Policy or of any party's rights or law.
          </p>
          <h6>Liability</h6>
          <p>
            You agree not to hold www.looktaza.com or any of its officers,
            employees, agents responsible or accountable for any of your or
            other user's listing, postings or information and nor shall we, our
            officers, employees or agents be liable for any misuse, illegal
            activity or third party content as postings, listings or information
            are generated by various users directly and we do not have any role
            in the creation, publication or distribution of the posting, listing
            or information, nor are we in a position to have editorial control
            over the substance or content contained in the listings, postings,
            or information, save and except to the extent provided in these
            terms.
          </p>
          <p>
            You understand and agree that we do not guarantee the accuracy or
            legitimacy of any listing, posting, information by other users. You
            further agree that we are not liable for any loss of money,
            goodwill, or reputation, or any special, indirect, or consequential
            damages arising out of your use of the site or as a result of any
            sale, purchase of goods and services with other users of the site.
            We also cannot guarantee continuous or secure access to our
            Services. Accordingly, to the extent legally permitted we exclude
            all implied warranties, of merchantability, fitness or quality of
            the Site and our services.
          </p>
          <h6>Personal Information</h6>
          <p>
            By using looktaza, you agree to the collection, transfer, storage
            and use of any personal information provided by you on the Site by
            looktaza India Private Limited. The data is stored and controlled on
            servers , India as further described in our Privacy Policy. By
            submitting your resume with your replies, you give permission to
            looktaza to publicly display your resume which can be freely
            accessed by anyone. You also agree to receive marketing
            communications from us unless you specifically indicate otherwise in
            writing to us through support@looktaza.com. You may send questions
            about this policy to support@looktaza.com.
          </p>
          <p>
            You agree that looktaza may use information shared by you to provide
            customer services and to understand if any other services or
            advertisement listed on looktaza website may interest you.
          </p>
          <p>
            You agree that, we may share personally identifiable data of any
            individual to our agents or recruiters or Affiliates defined as any
            person directly or indirectly controlling, controlled by, or under
            direct or indirect common control with, looktaza, including its
            subsidiaries and associate companies.
          </p>
          <p>
            You agree that on receipt of a written request from a person who has
            purchased goods or services, in accordance with law, looktaza may
            need to provide details of the Seller including:
          </p>
          <p>Address of headquarters and all branches</p>
          <p>Name and details of seller’s website</p>
          <p>E-mail address of Seller</p>
          <p>Other information necessary for communication with the Seller</p>
          <h6>Updates to this AUP and other terms and policies</h6>
          <p>
            We may update this AUP or the listing policy at any time and may
            notify you of such updates via a post on the boards and /or through
            email communications. The modified AUP and /or Listing Policy shall
            come into effect immediately. If we or our corporate affiliates are
            involved in a merger or acquisition, we may share personal
            information with another company, but this AUP shall continue to
            apply.
          </p>
          <p>
            You will also receive an e-mail once a year notifying you of any
            change to these Terms.
          </p>
          <p>
            You agree to receive an email atleast once a year informing you that
            in case of non-compliance with the Information Technology
            (Intermediaries Guidelines) Rules, 2011, these terms, policies, we
            have the right to terminate access or usage rights of users
            immediately or remove non-compliant information, or both.
          </p>
          <h6>Third Party Content and Services</h6>
          <p>
            looktaza may provide, on its site, links to sites operated by other
            entities. If the user decides to view this site, they shall do so at
            their own risk, subject to that site’s terms and conditions of use
            and privacy policy that may be different from those of this site. It
            is the user’s responsibility to take all protective measures to
            guard against viruses or other destructive elements they may
            encounter on these sites. looktaza makes no warranty or
            representation regarding, and do not endorse any linked website or
            the information appearing thereon or any of the products or services
            described thereon.
          </p>
          <p>
            Further, user’s interactions with organizations and/or individuals
            found on or through the service, including payment and delivery of
            goods and services, and any other terms, conditions, warranties or
            representations associated with such dealings, are solely between
            the user and such organization and/or individual. The user should
            make whatever investigation they feel necessary or appropriate
            before proceeding with any offline or online transaction with any of
            these third parties.
          </p>
          <h6>Indemnity</h6>
          <p>
            The User agrees to indemnify and hold looktaza, its officers,
            subsidiaries, affiliates, successors, assigns, directors, officers,
            agents, service providers, suppliers and employees, harmless from
            any claim or demand, including reasonable attorney fees and court
            costs, made by any third party due to or arising out of content
            submitted by the user, users use of the service, violation of the
            TOU, breach by the user of any of the representations and warranties
            herein, or user’s violation of any rights of another.
          </p>
          <h6>Governing Law & Jurisdiction</h6>
          <p>
            This AUP and the Listing Policy shall be governed and construed in
            accordance with the laws of the Republic of India and the courts at
            Bengaluru shall have exclusive jurisdiction on all matters and
            disputes arising out of and relating to the Site.
          </p>
          <h6>Make an Offer/Buy Now Terms</h6>
          <p>looktaza Assurance for Buyers</p>
          <p>
            You should "Make an Offer" of the amount which you wish to pay for
            the product.
          </p>
          <p>Once your offer is accepted, you can make the payment online.</p>
          <p>
            The listed item will be delivered to you by our logistics partner,
            and only after the delivery, payment will be released to seller.
          </p>
          <p>
            The BUY NOW purchase option enables a quick sale and implies that
            you agree to buy the product at the listed price. We will facilitate
            the payment directly and notify the seller. Once the payment has
            been made, we will schedule and complete the delivery. If you wish
            to negotiate, please choose 'Make an Offer' purchase option.
          </p>
          <p>Convenience fee</p>
          <p>For buyers:</p>
          <p>
            The fees applicable depend on the city, weight, category and price
            of the item you are purchasing. Click here to understand the
            specific charges that may apply.
          </p>
          <p>
            There is a penalty of Rs. 200 for any cancellation initiated by the
            buyer after payment is made.
          </p>
          <p>For sellers:</p>
          <p>
            The fees applicable fees depend on the city, weight, category and
            price of the item you are selling. Click here to understand the
            specific charges that may apply.
          </p>
          <h6>Mobile Buy Back Guarantee:</h6>
          <p>Guaranteed buy back is applicable only for Mobile phones.</p>
          <p>
            Guaranteed buy back is applicable when a user transact online and
            pay via looktaza doorstep service and / or Self pick-up flow.
          </p>
          <p>
            Guaranteed buy back is only available in select cities only :
            Bangalore, Delhi NCR, Mumbai, Hyderabad and Pune.
          </p>
          <p>
            The above offering is not applicable for users who transact offline.
          </p>
          <p>
            Guaranteed buy back is applicable for a select period . User needs
            to avail the offer on or before 30th Oct'2017.
          </p>
          <p>
            Guaranteed buy back is applicable on select smartphone brands only -
            Apple, Samsung, Xiaomi, Motorola, One plus, Oppo, vivo. The offer is
            not applicable on feature phones.
          </p>
          <p>
            Regular Doorstep / Self pickup charges are applicable for the
            transaction.
          </p>
          <p>looktaza doesn't certify the product purchased online.</p>
          <h6>looktazaJobs:</h6>
          <p>
            As a job-seeker you agree that looktaza will not be responsible for
            any payments made to a third-party (including verified companies).
          </p>
          <p>
            As a recruiter you agree that misuse or fraud use of this platform
            will result in deactivation and/or further action. You also agree to
            adhere to all job posting rules.
          </p>
          <h6>No Guarantee of Business:</h6>
          <p>
            looktaza does not guarantee business from the leads generated.
            looktaza shall not be responsible or liable at all to the Advertiser
            if no business or business leads are generated for the Advertiser
            through premium ads on the Website. Advertiser understands that
            looktaza’s only obligation is to place the premium ads on the
            Website in the manner provided for in these Terms. Accuracy of the
            information/content provided is the advertiser's responsibility and
            looktaza will not be held responsible for false claims made by the
            advertiser.
          </p>
          <h6>Notice of Infringement of Intellectual Property</h6>
          <p>
            looktaza is not liable for any infringement of intellectual property
            rights arising out of materials posted on or transmitted through the
            site, or items advertised on the site, by end users or any other
            third parties
          </p>
          <p>
            If you are an owner of intellectual property rights or an agent who
            is fully authorised to act on behalf of the owner of intellectual
            property rights and believe that any Content or other content
            infringes upon your intellectual property right or intellectual
            property right of the owner on whose behalf you are authorised to
            act, you may write to us at support@looktaza.com to delete the
            relevant Content in good faith
          </p>
          <h6>looktaza Easy</h6>
          <p>
            We strongly recommend our users to exercise their discretion & due
            diligence about all relevant aspects prior to availing any
            products/services. Please note that looktaza India Pvt. Ltd. does
            not implicitly or explicitly endorse any product/s or services
            provided by advertisers/service providers.
          </p>
          <p>
            The information related to name, address, contact details of the
            business establishments have been verified as existing at the time
            of registering any advertiser with looktaza India Pvt. Ltd Only
            (Premium and Top Choice customers). This verification is solely
            based on the documents as supplied by an advertiser/s or as per the
            details contained in Customer Registration Form.
          </p>
          <p>
            Service providers at all times ensure that all the applicable laws
            that govern their profession are followed while rendering their
            services.
          </p>
          <p>
            We strongly recommend our users to exercise their discretion & due
            diligence about all relevant aspects prior to availing any
            products/services. Please note that looktaza India Pvt. Ltd. does
            not implicitly or explicitly endorse any product/s or services
            provided by advertisers/service providers. The information related
            to name, address, contact details of the business establishments
            have been verified as existing at the time of registering any
            advertiser with looktaza India Pvt. Ltd Only (Premium and Top Choice
            customers). This verification is solely based on the documents as
            supplied by an advertiser/s or as per the details contained in
            Customer Registration Form.
          </p>
          <h6>General conditions</h6>
          <p>1. Reasonable Precaution and Care of Property:</p>
          <p>
            YOU/owner/ Person(s) shall take all reasonable precautions for
            safety and soundness of item covered to prevent the loss in order to
            minimise claims. The owner /Person must comply with manufacturer’s
            recommended actions for use, inspection, maintenance along with
            other instructions and shall comply with all statutory requirements
            or other regulations.
          </p>
          <p>2.Mis description:</p>
          <p>
            This Protection Plan shall be void and all premium paid by YOU/owner
            Person(s) to US shall be forfeited in the event of
            misrepresentation, misdescription or concealment of any material
            information.
          </p>
          <p>3.Changes in Circumstances</p>

          <p>4.Claim Procedure and Requirements</p>
          <p>
            Upon happening of an event giving rise or which may give rise to a
            claim, Beneficiary shall forthwith give notice in writing in person
            or courier to syncNscan in with full particulars. A written
            statement of the claim will be required and a claim form will be
            provided. This written statement of claim along with supporting
            documents (estimates, bill and the like) prepared at your expense or
            expense on your behalf along with particulars of other Protection
            Plan covering the same risk must be delivered to Service Provider
            within 15 days of date of Loss at owners/ persons expense.
          </p>
          <p>5.Fraud</p>
          <p>
            If any claim under this Protection Plan is fraudulent in any respect
            with or without Owner/ Person’s knowledge or if any fraudulent means
            or devices are used by the owner/ Person or on behalf of owner/
            Person to obtain any benefit under this Protection Plan, all
            benefits and rights under the Protection Plan shall be forfeited.
          </p>
          <p>6.Interest/Penalty</p>
          <p>
            No sum payable under this Protection Plan shall carry any interest
            or penalty.
          </p>
          <p>7.Geographical Scope</p>
          <p>
            The geographical scope of this Protection Plan will be the whole of
            India. The laws of India shall govern the provisions. The parties
            hereto unconditionally submit to the jurisdiction of the courts in
            Delhi
          </p>
        </div>
      </section>
    </>
  );
}

export default Term;
