import "./App.css";
import "./assets/css/allfile.css";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Pages from "./components/blogs/Pages";
import ErrorPage from "./container/ErrorPage";
import UserLayout from "./layout/UserLayout";
import ContactUs from "./container/ContactUs";
import PageLayout from "./layout/PageLayout";
import Math from "./components/blogs/Math";
import ServiceProvider from "./container/ServiceProvider";
import Signup from "./container/Signup";
import Login from "./container/Login";
import ProviderForm from "./container/ProvidersForm";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditPage from "./components/EditPage";
import "bootstrap/dist/css/bootstrap.min.css";
import FullDetails from "./container/FullDetails";
import ForgetPassword from "./components/ForgetPassword";

function App() {
  return (
    <>
      {/* <ToastContainer
        position="top-center"
        autoClose={3500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      /> */}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Routes>
        <Route path="signup" element={<Signup />} />
        <Route path="login" element={<Login />} />
        <Route path="forget" element={<ForgetPassword />} />
        <Route path="/" element={<UserLayout />}>
          <Route index element={<Home />} />
          <Route path="/:contactus" element={<ContactUs />} />
          {/* <Route path="/:contactDetails" element={<ContactUs />} /> */}
          <Route path="/:aboutus" element={<ContactUs />} />
          <Route path="/:term" element={<ContactUs />} />
          <Route path="/:policies" element={<ContactUs />} />
          <Route path="/providerForm" element={<ProviderForm />} />
          <Route path="/editPage" element={<EditPage />} />
        </Route>
        <Route path="/pages" element={<PageLayout />}>
          <Route path="/pages" element={<Pages />} />
          <Route path="/pages/math" element={<Math />} />
          <Route
            path="/pages/en-ind/:serviceProvider/:location/:state"
            element={<ServiceProvider />}
          />
          <Route path="/pages/:mainId/:providerId" element={<FullDetails />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      {/* <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pages" element={<UserLayout />}>
          <Route index element={<Pages />} />
          <Route path="/pages/contactUs" element={<ContactUs />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes> */}
    </>
  );
}

export default App;
