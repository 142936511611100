import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { endPoints } from "../configs/AppConfig";
import axios from "axios";
import { useCookies } from "react-cookie";
import CryptoJS from "crypto-js";

function Login() {
  const SECRET_KEY = "iamsatyaprasannawebdeveloperfromamerica";
  const navigate = useNavigate();
  const [inputData, setInputData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [cookies, setCookie] = useCookies(["user"]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputData.email || !inputData.password) {
      toast.warn("All fields are required");
    } else {
      const url = endPoints().userLoginUrl;
      const fData = new FormData();
      fData.append("email", inputData.email);
      fData.append("password", inputData.password);
      setLoading(true);

      await axios
        .post(url, fData)
        .then((res) => {
          setLoading(false);
          // console.log(res.data);

          if (res.data[0].status === "active") {
            setInputData({ email: "", password: "" });
            toast.success("Login success");
            let expires = new Date();
            expires.setTime(expires.getTime() + 2629746000);

            setCookie("user", inputData.email, { path: "/", expires });
            setCookie("userName", res.data[0].name, { path: "/", expires });
            setCookie("userId", res.data[0].id, { path: "/", expires });
            setCookie("IsServiceProvider", res.data[0].IsServiceProvider, {
              path: "/",
              expires,
            });

            const encrypted = CryptoJS.AES.encrypt(
              JSON.stringify(res.data[0].token),
              SECRET_KEY
            ).toString();
            // localStorage.setItem("name", encrypted);
            setCookie("secret", encrypted, { path: "/", expires });
            // localStorage.setItem("userDetails", JSON.stringify(res.data));

            navigate("/");
          } else {
            toast.error(res.data);
          }
        })
        .catch((err) => toast.error(err));
    }
  };
  return (
    <div className="container-fluid form_wall">
      <div className="row ">
        <div className="form_box m-auto col-lg-10 ">
          <div className="form_card ">
            <div className=" form_left"></div>
            <div className="form_right login_form_card">
              <form>
                <div className="form-row">
                  <div className="form-group mt-2">
                    <label htmlFor="inputEmail4">Email *</label>
                    <input
                      type="email"
                      className="form-control"
                      id="inputEmail4"
                      placeholder="Email"
                      value={inputData.email}
                      onChange={(e) =>
                        setInputData({ ...inputData, email: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="inputPassword4">Password *</label>
                    <input
                      type="password"
                      className="form-control"
                      id="inputPassword4"
                      placeholder="Password"
                      value={inputData.password}
                      onChange={(e) =>
                        setInputData({ ...inputData, password: e.target.value })
                      }
                    />
                  </div>
                  <Link to="/forget" style={{ textDecoration: "none" }}>
                    {" "}
                    <div className="mt-2">Forgot password</div>
                  </Link>
                </div>

                <div className=" d-flex justify-content-end">
                  <button
                    onClick={(e) => handleSubmit(e)}
                    className="btn btn-primary mt-5 "
                    style={{
                      background: "#3a9ad6",
                      border: "none",
                      overflow: "hidden",
                      height: "45px",
                    }}
                  >
                    {loading ? (
                      <div
                        style={{
                          position: "relative",
                          // left: "50%",
                          width: "100px",
                          zIndex: "999",
                          top: "-70%",
                          // height: "45px",
                        }}
                      >
                        <lottie-player
                          // className="wow animate__animated animate__backInRight"
                          src="https://lottie.host/96c1b886-87c6-4cc5-999e-4360320ecf2a/0oTQR8dtOK.json"
                          background="transparent"
                          speed=".5"
                          // style="height: 60%;"
                          style={{ height: "30%" }}
                          loop
                          autoplay
                        ></lottie-player>
                      </div>
                    ) : (
                      "Login"
                    )}
                  </button>
                </div>
                <div>
                  <Link to="/signup">Create account</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
