import { combineReducers } from "@reduxjs/toolkit";
import domManipulations from "./domManipulations";
import locationService from "./locationService";
import serviceProvider from "./serviceProvider";
import services from "./services";

const appReducers = combineReducers({
  domManipulations,
  locationService,
  serviceProvider,
  services,
});

export default appReducers;
