import React, { useEffect, useState } from "react";
import ServiceCard from "../components/ServiceCard";
import wintWall from "../assets/images/wintWall.avif";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { endPoints } from "../configs/AppConfig";
import axios, { all } from "axios";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { fetchServices } from "../actions/services";
import apiClient from "../apiClient";
import { Helmet } from "react-helmet";

function ServiceProvider() {
  const dispatch = useDispatch();
  const { services } = useSelector((state) => state.services);

  const [loading, setLoading] = useState(false);
  // const [imageIs, setImageIs] = useState([]);
  // console.log(services);
  const [allData, setAllData] = useState([]);
  const params = useParams();
  // console.log(params, "..........");
  const handleSubmit = async () => {
    dispatch(fetchServices(params));
    // const h = false;
    // if (h) {
    //   const url = endPoints().defaultLocationUrl;
    //   const fData = new FormData();
    //   // fData.append("location", params.location && params.location);
    //   fData.append(
    //     "serviceProvider",
    //     params.serviceProvider ? params.serviceProvider : "serviceEnd"
    //   );

    //   setLoading(true);
    //   // apiClient.get(endPoints().cardDataUrl +`?_location=${params.location || 'locationEnd'}&_serviceProvider=${}`)
    //   await axios
    //     .post(url, fData)
    //     .then((res) => {
    //       setLoading(false);
    //       if (res.data[0].address) {
    //         setAllData(res.data);
    //         toast.success(res.data);
    //         // console.log(res.data);
    //         // setImageIs(JSON.parse(res.data[0].image));
    //       } else {
    //         toast.warn(res.data);
    //         setAllData([]);
    //       }
    //     })
    //     .catch((err) => toast.error(err));
    // } else {
    // const url =
    //   endPoints().cardDataUrl +
    //   `&location=${params.location}&serviceProvider=${params.serviceProvider}&state=${params.state}`;
    // const fData = new FormData();
    // fData.append(
    //   "location",
    //   params.location ? params.location : "locationEnd"
    // );
    // fData.append(
    //   "serviceProvider",
    //   params.serviceProvider ? params.serviceProvider : "serviceEnd"
    // );
    // fData.append("state", params.state ? params.state : "stateEnd");

    // setLoading(true);
    // console.log(fData, "===");
    // dispatch(fetchServices(params));
    // await axios
    //   .get(url)
    //   .then((res) => {
    //     console.log(res.data, ".....");
    //     setLoading(false);
    //     if (res.data[0].address) {
    //       setAllData(res.data);
    //       toast.success(res.data);
    //       // console.log(res.data);
    //       // setImageIs(JSON.parse(res.data[0].image));
    //     } else {
    //       toast.warn(res.data);
    //       setAllData([]);
    //     }
    //   })
    //   .catch((err) => toast.error(err));
    // }
  };

  useEffect(() => {
    handleSubmit();
    window.scrollTo(0, 0);
  }, [params]);

  return (
    <div className="container-fluid ">
      <Helmet>
        <title>
          {params.serviceProvider} | Your Ultimate Classified Marketplace
        </title>
        <meta
          name="description"
          content={`Discover endless possibilities on ${params.serviceProvider} – your one-stop classified marketplace for a wide array of services. Explore, connect, and find what you need effortlessly.`}
        />
      </Helmet>
      {loading && (
        <div
          style={{
            position: "absolute",
            left: "10%",
            // width: "100px",
            zIndex: "999",
            top: "40%",
            height: "10rem",
            width: "100%",
          }}
        >
          <lottie-player
            // className="wow animate__animated animate__backInRight"
            src="https://lottie.host/2a5b810a-f29d-463c-8ab6-5d61739be89f/lPyIzzbJDy.json"
            background="transparent"
            speed=".3"
            // style="height: 60%;"
            style={{ height: "80%" }}
            loop
            autoplay
          ></lottie-player>
        </div>
      )}
      <div className="row ">
        <div className="col-lg-12 d-flex bg-secondar service_card_box  m-auto mt-4">
          {Array.isArray(services) && services?.length > 0 ? (
            services?.map((data) => {
              return (
                <ServiceCard
                  key={data.id}
                  idIs={data.id}
                  userId={data.userId}
                  service={data.serviceName}
                  location={data.pointedLocation}
                  stateName={data.state}
                  postedOn={moment(data.date).format("LL")}
                  serviceDetails={data.description}
                  imgSrc={data.image ? JSON.parse(data.image) : wintWall}
                />
              );
            })
          ) : (
            <>
              <div
                style={{
                  position: "absolute",
                  // left: "30%",
                  // width: "100px",
                  zIndex: "99",
                  top: "20%",
                  height: "30rem",
                  width: "50%",
                }}
              >
                <lottie-player
                  // className="wow animate__animated animate__backInRight"
                  src="https://lottie.host/e97b393c-5339-4b33-b1a4-f64e32fe7d6e/iXV8VcHXQC.json"
                  background="transparent"
                  speed=".3"
                  // style="height: 60%;"
                  style={{ height: "80%" }}
                  loop
                  autoplay
                ></lottie-player>
              </div>
              <div
                // style={{
                //   position: "absolute",
                //   bottom: "60px",
                //   display: "flex",
                //   width: "30%",
                //   justifyContent: "space-between",
                //   zIndex: "100",
                // }}
                className="IamBtnn"
              >
                <Link to="/" type="button" class="btn btn-primary btn-lg">
                  Go to home
                </Link>
                <Link
                  to="/providerForm"
                  type="button"
                  class="btn btn-secondary btn-lg"
                >
                  Create new
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ServiceProvider;
