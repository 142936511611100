import axios from "axios";
import { toast } from "react-toastify";
import { API_URL, endPoints } from "../configs/AppConfig";
// import { logout } from "utils/helper";
// import { getFromLocalstorage, setToLocalstorage } from "utils/dataStorage";
import store from "../store";
// import ActionNames from "configs/ActionNames";
// import { setCurrentWorkspace } from "actions";
const NETWORK_ERROR = "Network Error";
/**
 * Handle Errors
 *
 * @param errorResponse
 */
function handleErrors(errorResponse) {
  const { data } = errorResponse;
  console.log(data, "klinton");
  toast.error((data && data.error) || "Server Error");
}

const TIMEOUT = 600000;
const apiClient = axios.create({
  baseURL: API_URL,
  timeout: TIMEOUT,
  headers: {
    "Content-Type": "application/json",
  },
});

const hasTokenExpired = (error) =>
  error.response.data &&
  error.response.data.error &&
  error.response.data.error.message &&
  error.response.data.error.message === "Token expired" &&
  !error.config._retry;
const hasTokens = (response) =>
  response.data.data &&
  response.data.data.accessToken &&
  response.data.data.refreshToken;
const isUnauthorizedErr = (error) =>
  error.statusCode === 401 || (error.response && error.response.status === 401);
// const planLimitExceed = (error) =>
//   error.statusCode === 403 ||
//   (error.response && error.response.status === 403 && error.response.data.error.name === 'limitExceeded');

/**
 * API Client Request Interceptor
 */
apiClient.interceptors.request.use(async function (config) {
  // const accessToken = localStorage.getItem(LS_CONFIGS.tokenKey) || "";
  // const userData = getFromLocalstorage(LS_CONFIGS.userDataKey, true);
  // config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : null;
  // config.headers.Workspace =
  //   userData && userData.currentWorkspaceId
  //     ? userData.currentWorkspaceId
  //     : null;
  return config;
});

/**
 * API Client Response Interceptor
 */
apiClient.interceptors.response.use(
  (response) => {
    // console.log(response, "cloent...");
    // if (hasTokens(response)) {
    // localStorage.setItem(LS_CONFIGS.tokenKey, response.data.data.accessToken);
    // localStorage.setItem(
    //   LS_CONFIGS.rTokenKey,
    //   response.data.data.refreshToken
    // );
    // }
    // Workspace will be updated to localstorage when user will switch it.
    // if (response?.data?.data?.currentWorkspaceId)
    //   store.dispatch(
    //     setCurrentWorkspace(response.data.data.currentWorkspaceId)
    //   );

    return response;
  },
  async (error) => {
    // eslint-disable-next-line no-console
    console.log("API CLIENT ERROR : ", error);

    // Handle UnAuthorized Errors
    if (isUnauthorizedErr(error)) {
      const originalConfig = error.config;
      // const refreshToken = localStorage.getItem(LS_CONFIGS.rTokenKey);
      if (hasTokenExpired(error)) {
        originalConfig._retry = true;
        // Refresh tokens
        try {
          // await apiClient.post(endPoints().tokenRefreshUrl);
          return apiClient(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      } else {
        if (error.config.url !== endPoints().loginUrl) {
          // logout();
        }
      }
    }
    // else if (planLimitExceed(error)) {
    //   store.dispatch({
    //     type: ActionNames.SET_LIMIT_REACHED_MSG,
    //     response: error.response.data.error.message,
    //   });
    // }

    if (!error.config.hideError) {
      if (error.response) {
        handleErrors(error.response);
      } else {
        const networkErrorToastId =
          error.message === NETWORK_ERROR ? "network-error-toast" : "";
        if (error.message === `timeout of ${TIMEOUT}ms exceeded`) {
          toast.error(NETWORK_ERROR, {
            toastId: networkErrorToastId,
          });
        } else {
          toast.error(
            error.message,
            networkErrorToastId ? { toastId: networkErrorToastId } : {}
          );
        }
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;
