import React, { useEffect, useState } from "react";
import MyEditModal from "./MyEditModal";
import { useNavigate } from "react-router-dom";

function ServiceCard({
  service,
  location,
  stateName,
  postedOn,
  imgSrc,
  serviceDetails,
  IsServiceProvider,
  handleDelete,
  idIs,
  userId,
  allData,
  fetchRelatedData,
}) {
  const [myAllData, setmyAllData] = useState([]);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const handleEdit = (e, id) => {
    setShow(true);
    e.preventDefault();
    setmyAllData(allData.filter((spl) => spl.id == id));
  };
  const handleNavigate = (id, uid) => {
    navigate(`/pages/${id}/${uid}`);
  };

  return (
    <div className="service_card ">
      {service && (
        <div className="card col" style={{ width: "20rem" }}>
          {imgSrc && (
            // eslint-disable-next-line jsx-a11y/img-redundant-alt
            <>
              {imgSrc.length == 1 ? (
                // eslint-disable-next-line jsx-a11y/img-redundant-alt
                <img
                  className="card-img-top myImgSpl"
                  // src={imgSrc[0] > 0 ? imgSrc[0] : wintWall}
                  onClick={() => handleNavigate(idIs, userId)}
                  src={imgSrc[0]}
                  alt="Card image cap"
                  loading="lazy"
                  // style={{ height: "40vh", objectFit: "cover" }}
                />
              ) : (
                <div
                  id={`carouselExampleAutoplaying${idIs}`}
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-inner">
                    {imgSrc.map((spl, index) => {
                      return (
                        <div
                          key={index}
                          className={`carousel-item ${
                            index === 0 && "active"
                          } `}
                        >
                          <img
                            onClick={() => handleNavigate(idIs, userId)}
                            src={spl}
                            className="d-block w-100 card-img-top"
                            loading="lazy"
                            alt="..."
                          />
                        </div>
                      );
                    })}
                    {/* <div className="carousel-item active">
                      <img src="..." className="d-block w-100" alt="..." />
                    </div>
                    <div className="carousel-item">
                      <img src="..." className="d-block w-100" alt="..." />
                    </div>
                    <div className="carousel-item">
                      <img src="..." className="d-block w-100" alt="..." />
                    </div> */}
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target={`#carouselExampleAutoplaying${idIs}`}
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target={`#carouselExampleAutoplaying${idIs}`}
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              )}
            </>
          )}

          <div
            onClick={() => handleNavigate(idIs, userId)}
            className="card-body"
            style={{ cursor: "pointer" }}
          >
            {service && (
              <div className="d-flex justify-content-between">
                <h6>{service}</h6>
              </div>
            )}
            {location && (
              <small className="service_location">
                Location:
                <span style={{ textTransform: "capitalize" }}> {location}</span>
                ,
                <span style={{ textTransform: "capitalize" }}>{stateName}</span>
              </small>
            )}
            <br />
            <small className="service_location">{postedOn}</small>
            {serviceDetails && (
              <p className="card-text myContent">{serviceDetails}</p>
            )}
          </div>
          {IsServiceProvider && (
            <div className="btn-group" role="group" aria-label="Basic example">
              <button
                type="button"
                className="btn btn-danger"
                onClick={(e) => handleDelete(e, idIs)}
              >
                Delete
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                onClick={(e) => handleEdit(e, idIs)}
              >
                Edit
              </button>
            </div>
          )}
        </div>
      )}
      {/* {showing > 0 && <EditModal allData={myAllData} />} */}
      <MyEditModal
        show={show}
        allData={myAllData}
        handleClose={handleClose}
        fetchRelatedData={fetchRelatedData}
      />
    </div>
  );
}

export default ServiceCard;
