import apiClient from "../apiClient";
import actions from "../configs/ActionNames";
import { endPoints } from "../configs/AppConfig";

export function fetchLocation(scheduled) {
  return {
    types: [
      actions.FETCH_LOCATION,
      actions.FETCH_LOCATION_SUCCESS,
      actions.FETCH_LOCATION_FAILED,
    ],
    shouldCallAPI: (state) => !state.locationService.isFetching,
    callAPI: () => apiClient.get(endPoints().getLocationUrl),
  };
}
