import ActionNames from "../configs/ActionNames";

const defaultState = {
  closeSidebar: false,
  handleNavbar: false,
  data: [],
};

export default function domManipulations(state = defaultState, action) {
  switch (action.type) {
    case ActionNames.CLOSE_SIDEBAR:
      return { ...state, closeSidebar: action.payload };

    case ActionNames.HANDLE_NAV_BAR:
      return { ...state, handleNavbar: action.payload };

    default:
      return state;
  }
}
