import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useSelector } from "react-redux";
import Footer from "../components/Footer";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { useCookies } from "react-cookie";
import UserSearch from "../components/UserSearch";
import axios from "axios";
import { endPoints } from "../configs/AppConfig";
import { toast } from "react-toastify";
const PageLayout = () => {
  const { closeSidebar } = useSelector((state) => state.domManipulations);
  const [allLocation, setAllLocation] = useState([]);

  const [cookies, removeCookie] = useCookies(["user"]);
  const [user, setUser] = useState(false);
  const [userName, setUserName] = useState("");
  const [isServiceProvider, setIsServiceProvider] = useState(false);
  const [colorChange, setColorchange] = useState(false);

  const handleLogout = () => {
    removeCookie("user");
    removeCookie("IsServiceProvider");
    removeCookie("secret");
  };

  const fetchLocation = async () => {
    const url = endPoints().getLocationUrl;

    await axios
      .get(url)
      .then((res) => {
        // console.log(res.data);
        setAllLocation(res.data);
      })
      .catch((err) => toast.error(err));
  };

  // console.log(isServiceProvider, user);
  useEffect(() => {
    if (cookies?.user && cookies?.user !== "undefined") {
      setUser(true);
      setUserName(cookies?.user);
    } else {
      setUser(false);
    }

    if (cookies?.IsServiceProvider == true) {
      setIsServiceProvider(true);
    } else {
      setIsServiceProvider(false);
    }
  }, [handleLogout]);
  const changeNavbarColor = () => {
    if (window.scrollY >= 90) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };

  window.addEventListener("scroll", changeNavbarColor);
  useEffect(() => {
    fetchLocation();
  }, []);

  return (
    <div>
      <Header
        rightAerrow={true}
        showShignupbtn
        user={user}
        isServiceProvider={isServiceProvider}
        handleLogout={handleLogout}
        userName={userName}
      />
      <div className="container-fluid ">
        <div
          className="banner_head"
          style={
            colorChange
              ? {
                  position: "fixed",
                  zIndex: "1000",
                  top: "0",
                  width: "100%",
                  left: "0",
                  backgroundColor: "ghostwhite",
                  height: "12vh",
                }
              : {
                  position: "relative",
                  zIndex: "1000",
                  width: "70%",
                  left: "17%",
                  top: "5vh",
                }
          }
        >
          <h2 className="homeText">
            <span style={{ color: "#9400ff" }}>Let's </span> Explore{" "}
            {colorChange && "Together"}
          </h2>
          <UserSearch allLocation={allLocation} />
        </div>
        <div className="row d-flex my-5" style={{ minHeight: "100vh" }}>
          <div className="d-flex vol-sm-12 col-m-12 col-lg-12 p-0 m-0 ">
            <Outlet />
          </div>
        </div>
      </div>
      <Footer userName={userName} />
    </div>
  );
};

export default PageLayout;
