// export const API_URL = "http://localhost/all/look/server";
export const API_URL = "https://looktaza.com/server";

export const endPoints = () => ({
  //   signupUrl: `${API_URL}/adminHandle.php?signup`,
  userSignupUrl: `${API_URL}/adminHandle.php?userSignup`,
  //   loginUrl: `${API_URL}/adminHandle.php?login`,
  userLoginUrl: `${API_URL}/adminHandle.php?userLogin`,
  uploadUrl: `${API_URL}/adminHandle.php?upload`,
  updateUrl: `${API_URL}/adminHandle.php?update`,
  cardDataUrl: `${API_URL}/adminHandle.php?cardData`,
  defaultLocationUrl: `${API_URL}/adminHandle.php?defaultLocation`,
  getLocationUrl: `${API_URL}/adminHandle.php?getLocation`,
  locationUrl: `${API_URL}/adminHandle.php?location`,
  locationUpdateUrl: `${API_URL}/adminHandle.php?locationUpdate`,
  editDataUrl: `${API_URL}/adminHandle.php?editData`,
  deleteDataUrl: `${API_URL}/adminHandle.php?deletedata`,
  authLoginUrl: `${API_URL}/adminHandle.php?authLogin`,
  particularDataUrl: `${API_URL}/adminHandle.php?particularData`,
  providerDataUrl: `${API_URL}/adminHandle.php?providerData`,
  forgetUrl: `${API_URL}/adminHandle.php?forget`,
  contactUsUrl: `${API_URL}/contactus.php?contactUs`,
  userContactToProviderUrl: `${API_URL}/contactus.php?userContactToProvider`,
  feedbackUrl: `${API_URL}/contactus.php?feedback`,
  //   allBookingsUrl: `${API_URL}/parking.php?allBookings`,
  //   changeSlotUrl: `${API_URL}/parking.php?changeSlot`,
  //   dynamicChangeSlotUrl: `${API_URL}/parking.php?dynamicChangeSlot`,
  //   userBookingUrl: `${API_URL}/parking.php?userBookingUrl=`,
  //   editAllProductsUrl: `${API_URL}/parking.php?edit=`,
  //   editUserRequestUrl: `${API_URL}/parking.php?editUserRequest=`,
  //   deleteProductUrl: `${API_URL}/parking.php?delete=`,
  //   changePendingUrl: `${API_URL}/parking.php?changePending=`,
  //   changeCompleteUrl: `${API_URL}/parking.php?changeComplete=`,
  //   deletePendingUrl: `${API_URL}/parking.php?deletePending=`,
});
