// /* eslint-disable */
const ActionName = {
  HANDLE_NAV_BAR: "HANDLE_NAV_BAR",
  CLOSE_SIDEBAR: "CLOSE_SIDEBAR",

  FETCH_SERVICE: "FETCH_SERVICE",
  FETCH_SERVICE_SUCCESS: "FETCH_SERVICE_SUCCESS",
  FETCH_SERVICE_FAILED: "FETCH_SERVICE_FAILED",

  FETCH_LOCATION: "FETCH_LOCATION",
  FETCH_LOCATION_SUCCESS: "FETCH_LOCATION_SUCCESS",
  FETCH_LOCATION_FAILED: "FETCH_LOCATION_FAILED",

  UPDATE_SERVICE: "UPDATE_SERVICE",
  UPDATE_SERVICE_SUCCESS: "UPDATE_SERVICE_SUCCESS",
  UPDATE_SERVICE_FAILED: "UPDATE_SERVICE_FAILED",
};
// /* eslint-enable */
export default ActionName;
