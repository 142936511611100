import React, { useEffect, useRef, useState, useCallback } from "react";
import { NavLink } from "react-router-dom";
import "../assets/css/Navbar.css";
// import SVGIcon from "./SVGIcon";
import { useDispatch, useSelector } from "react-redux";
import { openSidebar, hendleNavbar } from "../actions/domActions";
import useOnClickOutsideRef from "../hooks/useOnClickOutsideRef";
import { useCookies } from "react-cookie";
import logo from "../assets/images/logo-no.png";
const Header = ({
  rightAerrow,
  showShignupbtn,
  user,
  isServiceProvider,
  handleLogout,
  userName,
}) => {
  const [cookies, setCookie] = useCookies(["user"]);
  // const [user, setUser] = useState(false);
  // // const user = true;
  // const [isServiceProvider, setIsServiceProvider] = useState(false);
  // const isServiceProvider = true;
  const refference = useRef(null);
  const [showUserBox, setShowUserBox] = useState(false);
  const dispatch = useDispatch();
  const [showProfileModal, toggleProfileModal] = useState(false);

  const profileModalRef = useOnClickOutsideRef(
    useCallback(() => toggleProfileModal(false), [])
  );

  const { closeSidebar, handleNavbar } = useSelector(
    (state) => state.domManipulations
  );
  // console.log(handleNavbar, "handleNavbar-----");
  const handleSidebar = () => {
    dispatch(openSidebar(!closeSidebar));
  };

  const showNavbar = () => {
    dispatch(hendleNavbar(!handleNavbar));
  };

  return (
    <>
      <nav className=" navbar navbar-expand-lg nav_bar_box navbar-light navber_container ">
        <div className="container-fluid cotainer_div bg-dange d-flex ">
          {/* <div className=" row col-md-9 col-lg-11 relative"> */}
          {/* {rightAerrow && (
            <div
              className={`aerrow_div ${closeSidebar && "aerrow_rotate"} `}
              onClick={handleSidebar}
            >
              <i className="fa-solid fa-angle-right"></i>
            </div>
          )} */}
          <div className="nav_flex_box ">
            <div className="nav_menu ">
              <div className="logo_box bg">
                <NavLink to="/">
                  <img src={logo} alt="Logo" />
                </NavLink>
              </div>
              <div className={`list_box  ${handleNavbar && "list_box_show"} `}>
                <ul className="navbar-nav nav_ul">
                  <li className="nav-item">
                    <NavLink
                      onClick={() => dispatch(hendleNavbar(false))}
                      className="nav_link_text "
                      to="/"
                    >
                      Home
                    </NavLink>
                  </li>
                  <li className="">
                    <NavLink
                      onClick={() => dispatch(hendleNavbar(false))}
                      className="nav_link_text"
                      to="/providerForm"
                    >
                      Add new service
                    </NavLink>
                  </li>
                  <li className="">
                    <NavLink
                      onClick={() => dispatch(hendleNavbar(false))}
                      className="nav_link_text"
                      to="/contactUs"
                    >
                      Contact us
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>

            <div
              onClick={() => toggleProfileModal(!showProfileModal)}
              className="user_icon"
              ref={profileModalRef}
            >
              <p style={{ textTransform: "capitalize" }}>
                {user && userName ? userName.charAt(0) : "U"}
              </p>
              <div className="bg-info">
                {showProfileModal && (
                  <div className="user_details ">
                    {user && <p>{userName ? userName : "User"}</p>}
                    {user && isServiceProvider && (
                      <NavLink to="/providerForm" className="link_decoration">
                        <p>Add new service</p>
                      </NavLink>
                    )}
                    {user && isServiceProvider && (
                      <NavLink to="/editPage" className="link_decoration">
                        <p>Edit service</p>
                      </NavLink>
                    )}

                    {showShignupbtn && (
                      <div>
                        {!user && (
                          <NavLink className="link_decoration" to="/signup">
                            <div className="signup_btn">Sign up</div>
                          </NavLink>
                        )}
                        {!user && (
                          <NavLink className="link_decoration" to="/signup">
                            <div className="signup_btn">Add service</div>
                          </NavLink>
                        )}
                        {user ? (
                          <div
                            onClick={() => handleLogout()}
                            className="signup_btn"
                          >
                            Logout
                          </div>
                        ) : (
                          <NavLink className="link_decoration" to="/login">
                            <div className="signup_btn">Login</div>
                          </NavLink>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <div className="bar_header"> */}
          <div className="header_bar p-1" onClick={showNavbar}>
            <i className="fa-solid fa-bars"></i>
          </div>
          {/* </div> */}
        </div>
        {/* </div> */}
      </nav>
    </>
  );
};

export default Header;
