import React from "react";
import "../assets/css/footer.css";
import { useState } from "react";
import { FaFacebookSquare, FaInstagram, FaLinkedin } from "react-icons/fa";
import { endPoints } from "../configs/AppConfig";
import axios from "axios";
import { toast } from "react-toastify";
const Footer = ({ userName }) => {
  const [textContent, setTextContent] = useState("");
  const [feedBack, setFeedBack] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = endPoints().feedbackUrl;

    const fData = new FormData();
    fData.append("feedback", feedBack);
    fData.append("name", userName ? userName : "");

    await axios
      .post(url, fData)
      .then((res) => {
        if (res.data === "success") {
          setFeedBack("");
          toast.success("Your feedback means a lot to us...");
        }
      })
      .catch((err) => toast.error(err));
  };

  const handleText = (e) => {
    setTextContent(e.target.value);
  };
  return (
    // <>
    //   <div className="container-fluid">
    //     <div className="row">
    //       <div className="lol-12 footer_box">
    //         <div className="col-s-12 col-m-12 col-lg-8  bg-secondar m-auto d-fle footer_bo">
    //           <div className="col-s-12 col-lg-12 bg-inf d-flex footer_small_box_top">
    //             <div>Logo</div>
    //             <div>
    //               <span style={{ color: "#3b5998" }}>
    //                 <FaFacebookSquare />
    //               </span>
    //               <span
    //                 style={{
    //                   color: "#d62976 ",
    //                   marginRight: "5px",
    //                   marginLeft: "5px",
    //                 }}
    //               >
    //                 <FaInstagram />
    //               </span>
    //               <span style={{ color: "#3b4999" }}>
    //                 <FaLinkedin />
    //               </span>
    //             </div>
    //           </div>
    //           {/* <div className="col-s-12 col-lg-12 bg-dange  footer_small_box">
    //             second
    //           </div> */}
    //           <div className="col-s-12 col-lg-12 d-flex  footer_small_box_last">
    //             <div className="col-lg-3 bg-succes">
    //               {/* <h5></h5> */}
    //               <p>About us</p>
    //               <p>Contact us</p>
    //               <p>Trust center</p>
    //               <p>Legal</p>
    //               <p>Newsroom</p>
    //               <p>Calender</p>
    //               <p>Blog</p>
    //             </div>
    //             <div className="col-lg-3 bg-dange ">
    //               <p>Learn how our app work</p>
    //               <p>Community</p>
    //               <p>Affiliate</p>
    //             </div>
    //             <div className="col-lg-5 bg-succes d-flex flex-column w-25">
    //               <h5>Frequenly ask question</h5>
    //               <textarea
    //                 name=""
    //                 id=""
    //                 cols="30"
    //                 rows="4"
    //                 style={{ maxHeight: "5rem", minHeight: "5rem" }}
    //                 onChange={handleText}
    //               ></textarea>
    //               {textContent && (
    //                 <button
    //                   style={{
    //                     width: "5rem",
    //                     marginTop: "5px",
    //                     borderRadius: "5px",
    //                   }}
    //                 >
    //                   Submit
    //                 </button>
    //               )}
    //             </div>
    //           </div>
    //         </div>
    //         <div className="footer_rights col-8">
    //           <p>Copyright 2023 company | All rights reserved.</p>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </>
    <>
      <footer className="text-center text-lg-start footer_main text-white">
        <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
          <div className="me-5 d-none d-lg-block">
            <span>Get connected with us on social networks:</span>
          </div>

          <div>
            <a href="" className="me-4 link-secondary">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="" className="me-4 link-secondary">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="" className="me-4 link-secondary">
              <i className="fab fa-google"></i>
            </a>
            <a href="" className="me-4 link-secondary">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="" className="me-4 link-secondary">
              <i className="fab fa-linkedin"></i>
            </a>
            <a href="" className="me-4 link-secondary">
              <i className="fab fa-github"></i>
            </a>
          </div>
        </section>

        <section className="">
          <div className="container text-center text-md-start mt-5">
            <div className="row mt-3">
              <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  <i className="fas fa-gem me-3 text-secondary"></i>Looktaza
                </h6>
                <p>
                  Looktaza is all about you. We want every people get tehere
                  services easily and quickly.
                </p>
                <div className="input-group mb-3">
                  <textarea
                    class="form-control"
                    placeholder="Leave a comment here"
                    id="floatingTextarea"
                    rows="1"
                    value={feedBack}
                    onChange={(e) => setFeedBack(e.target.value)}
                  ></textarea>
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    id="button-addon2"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Send
                  </button>
                </div>
              </div>

              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Links</h6>
                <p>
                  <a href="#!" className="text-reset">
                    About Looktaza
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    Contact us
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    Terms of use
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    Policies
                  </a>
                </p>
              </div>

              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Useful links</h6>
                <p>
                  <a href="#!" className="text-reset">
                    Pricing
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    Settings
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    Orders
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    Help
                  </a>
                </p>
              </div>

              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                <p>
                  <i className="fas fa-home me-3 text-secondary"></i>{" "}
                  Bhubaneswar, India
                </p>
                <p>
                  <i className="fas fa-envelope me-3 text-secondary"></i>
                  looktaza.service@gmail.com
                </p>
                {/* <p>
                  <i className="fas fa-phone me-3 text-secondary"></i> + 01 234
                  567 88
                </p>
                <p>
                  <i className="fas fa-print me-3 text-secondary"></i> + 01 234
                  567 89
                </p> */}
              </div>
            </div>
          </div>
        </section>

        <div
          className="text-center p-4"
          // style="background-color: rgba(0, 0, 0, 0.025);"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.025)" }}
        >
          © {new Date().getFullYear()} Copyright:
          <a className="text-reset fw-bold" href="https://looktaza.com/">
            looktaza.com
          </a>
        </div>
      </footer>
    </>
  );
};

export default Footer;
