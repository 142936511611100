import hotel from "../photos/hotel.webp";
import tutor from "../photos/tutior.webp";
import room from "../photos/room.webp";
import plumber from "../photos/plumber.webp";
import electric from "../photos/electric.webp";
import softEngg from "../photos/softEngg.webp";
import seo from "../photos/seo.webp";
import cetreen from "../photos/cetreen.webp";
import photography from "../photos/photography.webp";
import signboard from "../assets/myImages/signBoardService.webp";
import waterpurifier from "../assets/myImages/waterPurifier.webp";
import elevator from "../assets/myImages/elevetorService.webp";
import camera from "../assets/myImages/cameraRepairService.webp";
import inverter from "../assets/myImages/inverterRepairing.webp";
import inductionstove from "../assets/myImages/inductionCooker.webp";
import buildingmaintenance from "../assets/myImages/buildingmaintenance.webp";
import logo from "../assets/myImages/logoDesign.webp";
import printing from "../assets/myImages/printingService.webp";
import childcare from "../assets/myImages/chileCare.webp";
import cook from "../assets/myImages/cookingService.webp";
import driver from "../assets/myImages/driverService.webp";
import maid from "../assets/myImages/maidService.webp";
import cleaning from "../assets/myImages/houseCleaner.webp";
import interiordesign from "../assets/myImages/interiorService.webp";
import modularkitchen from "../assets/myImages/modularKitchen.webp";
import housepainting from "../assets/myImages/painter.webp";
import buildingcontractor from "../assets/myImages/builder.webp";
import vastu from "../assets/myImages/vastuConsultant.webp";
import securitysystem from "../assets/myImages/securityService.webp";
import cctvcamera from "../assets/myImages/cctvService.webp";
import tifinService from "../assets/myImages/tifinService.webp";
import solar from "../assets/myImages/solarPanelService.webp";
import coaching from "../assets/myImages/coaching.webp";
import gstconsultant from "../assets/myImages/gstService.webp";
import travelBus from "../assets/myImages/travelService.webp";
import tourpackages from "../assets/myImages/tourService.webp";

const serviceName = [
  "Tution teacher",
  "Room rent",
  "Paying guest(pg)",
  "Plumber",
  "Electrician",
  "Application developer",
  "Web developer",
  "SEO export",
  "Make up",
  "Hotel",
  "AC service",
  "Electrical wiring",
  "TV service",
  "Washing machine service",
  "Fridge service",
  "Catering service",
  "Wedding Photography",
];
// const images = require.context("../photos", true);
// const imageList = images.keys().map((image) => images(image));

// console.log(imageList);
// console.log(images, "fhj");

const myServices = [
  {
    id: 1,
    name: "Sign Board Agencies",
    image: signboard,
  },
  {
    id: 2,
    name: "Water Purifier Dealers",
    image: waterpurifier,
  },
  {
    id: 3,
    name: "Elevator Repairs",
    image: elevator,
  },
  {
    id: 4,
    name: "Camera Repair Service",
    image: camera,
  },
  {
    id: 5,
    name: "Computer Repairs",
    image: "",
  },
  {
    id: 6,
    name: "Inverter Repairs",
    image: inverter,
  },
  {
    id: 7,
    name: "Induction Stove Repairs",
    image: inductionstove,
  },
  {
    id: 8,
    name: "Building Maintenance Services",
    image: buildingmaintenance,
  },
  {
    id: 9,
    name: "Logo Design Services",
    image: logo,
  },
  {
    id: 10,
    name: "Printing Services",
    image: printing,
  },
  {
    id: 11,
    name: "Child Care Services",
    image: childcare,
  },
  {
    id: 12,
    name: "Cook Services",
    image: cook,
  },
  {
    id: 13,
    name: "Driver Services",
    image: driver,
  },
  {
    id: 14,
    name: "Maid Services",
    image: maid,
  },
  {
    id: 15,
    name: "Cleaning Services",
    image: cleaning,
  },
  {
    id: 16,
    name: "Tiffin Services",
    image: tifinService,
  },
  {
    id: 17,
    name: "Interior Designers & Decorators",
    image: interiordesign,
  },
  {
    id: 18,
    name: "Modular Kitchen Dealers",
    image: modularkitchen,
  },
  {
    id: 19,
    name: "House Painting Services",
    image: housepainting,
  },
  {
    id: 20,
    name: "Building Contractors",
    image: buildingcontractor,
  },
  {
    id: 21,
    name: "Vastu Consultants",
    image: vastu,
  },
  {
    id: 22,
    name: "Security System Dealers",
    image: securitysystem,
  },
  {
    id: 23,
    name: "CCTV Camera Dealers",
    image: cctvcamera,
  },
  {
    id: 24,
    name: "Architects",
    image: "",
  },
  {
    id: 25,
    name: "Solar Product Dealers",
    image: solar,
  },
  {
    id: 26,
    name: "Coaching",
    image: coaching,
  },
  {
    id: 27,
    name: "CA Services",
    image: "",
  },
  {
    id: 28,
    name: "GST Consultants",
    image: gstconsultant,
  },
  {
    id: 29,
    name: "Company Registration Service",
    image: "",
  },
  {
    id: 30,
    name: "Tax Consultants",
    image: "",
  },
  {
    id: 31,
    name: "Loan Service",
    image: "",
  },
  {
    id: 32,
    name: "Bus Travel Agents",
    image: travelBus,
  },
  {
    id: 33,
    name: "Travel Agents",
    image: "",
  },
  {
    id: 34,
    name: "Air Travel Agents",
    image: "",
  },
  {
    id: 35,
    name: "Train Travel Agents",
    image: "",
  },
  {
    id: 36,
    name: "Domestic Tour Packages",
    image: tourpackages,
  },
  {
    id: 37,
    name: "Vehicle/Car Rental Service",
    image: "",
  },
  {
    id: 38,
    name: "Tution Teacher",
    image: tutor,
  },
];

// const country = ["Select", "India", "Nepal"];
const country = [
  {
    countryName: "India",
    state: [
      "Andhra Pradesh",
      "Arunachal Pradesh",
      "Assam",
      "Bihar",
      "Chhattisgarh",
      "Goa",
      "Gujarat",
      "Haryana",
      "Himachal Pradesh",
      "Jharkhand",
      "Karnataka",
      "Kerala",
      "Madhya Pradesh",
      "Maharashtra",
      "Manipur",
      "Meghalaya",
      "Mizoram",
      "Nagaland",
      "Odisha",
      "Punjab",
      "Rajasthan",
      "Sikkim",
      "Tamil Nadu",
      "Telangana",
      "Tripura",
      "Uttar Pradesh",
      "Uttarakhand",
      "West Bengal",
      "Andaman and Nicobar Islands",
      "Chandigarh",
      "Dadra and Nagar Haveli ",
      " Daman and Diu",
      "Lakshadweep",
      "Delhi",
      "Ladakh",
      "Lakshadweep",
      "Puducherry",
    ],
  },
  {
    countryName: "Nepal",
    state: ["Lumbini", "Gandaki", "Bagmati", "Koshi"],
  },
];

export default { serviceName, country, myServices };
