import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import callAPI from "./middlewares/callAPIMiddleware";
import appReducers from "./reducers/index";
// import { APP_ENV } from 'configs/AppConfigs';

const store = configureStore({
  reducer: appReducers,
  middleware: (getDefaultMiddleware) => [
    thunk,
    callAPI,
    ...getDefaultMiddleware(),
  ],
});

// if (APP_ENV === 'development') window.store = store;
export default store;
