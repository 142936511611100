import React from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import ContactDetails from "../components/contactPages/ContactDetails";
import AboutUs from "../components/contactPages/AboutUs";
import Policies from "../components/contactPages/Policies";
import Term from "../components/contactPages/Term";

const ContactUs = () => {
  const params = useParams();

  // console.log(params.contactus, "paraaa");
  return (
    <div className="bg-succe container">
      <div className="row">
        <div className="contact_menu">
          <Link className="con_menu_link" to="/contactus">
            <p
              className={`${
                (params.contactus === "contactUs" ||
                  params.contactus === "contactus") &&
                "con_selected_menu"
              }`}
            >
              Contact us
            </p>
          </Link>
          <Link className="con_menu_link" to="/aboutus">
            <p
              className={`${
                params.contactus === "aboutus" && "con_selected_menu"
              }`}
            >
              About us
            </p>
          </Link>
          <Link className="con_menu_link" to="/term">
            <p
              className={`${
                params.contactus === "term" && "con_selected_menu"
              }`}
            >
              {" "}
              Term & Conditions
            </p>
          </Link>
          <Link className="con_menu_link" to="/policies">
            <p
              className={`${
                params.contactus === "policies" && "con_selected_menu"
              }`}
            >
              {" "}
              Policies
            </p>
          </Link>
        </div>
        <div className="contacts_component">
          {(params.contactus === "contactUs" ||
            params.contactus === "contactus") && <ContactDetails />}
          {params.contactus === "aboutus" && <AboutUs />}
          {params.contactus === "policies" && <Policies />}
          {params.contactus === "term" && <Term />}
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
