import apiClient from "../apiClient";
import actions from "../configs/ActionNames";
import { endPoints } from "../configs/AppConfig";

export function fetchServices(params) {
  console.log(params, "stateeee");
  return {
    types: [
      actions.FETCH_SERVICE,
      actions.FETCH_SERVICE_SUCCESS,
      actions.FETCH_SERVICE_FAILED,
    ],
    shouldCallAPI: (state) => !state.services.isFetching,
    callAPI: () =>
      apiClient.post(
        endPoints().cardDataUrl +
          `&location=${params.location}&serviceProvider=${params.serviceProvider}&state=${params.state}`
      ),
  };
}
