import React from "react";
import Accorian from "../Accorian";
import { useSelector } from "react-redux";
// import { openSidebar } from "../../actions/domActions";
// import { Block } from "react-katex";
const Pages = () => {
  /* eslint-disable */
  const { closeSidebar } = useSelector((state) => state.domManipulations);

  const mathData = [
    {
      mfQuestion:
        "if $a,b,c$ whole number and $ab = c^3$. then $\\sqrt[3]ab=c$",
    },
  ];
  const Latex = require("react-latex");
  const question1 =
    "this is question no one. you have to answer this first. so please try to answer this.";
  const answer1 =
    "this is answer no one. you have to read carefully. so try to understand this.";

  return (
    <div className="container-fluid ">
      <div className="row">
        <div className="col-sm-12 com-m-12 col-lg-10 p-0 ">
          <div className="my-2">
            <Accorian question={question1} answer={answer1} />
            <Accorian />
            <div className="">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nihil,
              tempore?lorem10
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Latex>What is $(3\times 4) \div (5-3)$ </Latex>
                <Latex>what is the value of $2^3 = 8$</Latex>
                <Latex>what is the value of $4/ 2= 2 $</Latex>
                <Latex>what is the value of $\sqrt25$</Latex>
                <Latex>what is the value of $\sqrt[3]125$</Latex>
                <Latex>{mathData[0].mfQuestion}</Latex>
                <Latex>$\sqrt[3]ab=c$</Latex>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pages;
