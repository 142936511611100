import React, { useState } from "react";
import { toast } from "react-toastify";
import { endPoints } from "../../configs/AppConfig";
import axios from "axios";

function ContactDetails() {
  const [inputData, setInputData] = useState({
    name: "",
    email: "",
    subject: "",
    msg: "",
  });
  const [loading, setLoading] = useState(false);

  const handleSend = async (e) => {
    e.preventDefault();
    if (!inputData.name || !inputData.email || !inputData.msg) {
      toast.warn("All fields are required");
    } else {
      const url = endPoints().contactUsUrl;
      const fData = new FormData();
      fData.append("name", inputData.name);
      fData.append("email", inputData.email);
      fData.append("subject", inputData.subject);
      fData.append("msg", inputData.msg);

      setLoading(true);

      await axios
        .post(url, fData)
        .then((res) => {
          setLoading(false);

          if (res.data === "Success") {
            toast.success(
              `This message has been sent to our developer team, they will contact you soon`
            );
            setInputData({
              name: "",
              email: "",
              subject: "",
              msg: "",
            });
          } else {
            toast.warn(res.data);
          }
        })
        .catch((err) => toast.error(err));
    }
  };
  return (
    <>
      <section className="mb-4">
        <h2 className="h1-responsive font-weight-bold text-center my-4">
          Contact us
        </h2>

        <p className="text-center w-responsive mx-auto mb-5">
          Do you have any questions? Please do not hesitate to contact us
          directly. Our team will come back to you within a matter of hours to
          help you.
        </p>

        <div className="row">
          <div className="col-md-9 mb-md-0 mb-5">
            <form id="contact-form">
              <div className="row">
                <div className="col-md-6">
                  <div className="md-form mb-0">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="form-control"
                      value={inputData.name}
                      onChange={(e) =>
                        setInputData({ ...inputData, name: e.target.value })
                      }
                    />
                    <label htmlFor="name" className="">
                      Your name
                    </label>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="md-form mb-0">
                    <input
                      type="text"
                      id="email"
                      name="email"
                      className="form-control"
                      value={inputData.email}
                      onChange={(e) =>
                        setInputData({ ...inputData, email: e.target.value })
                      }
                    />
                    <label htmlFor="email" className="">
                      Your email
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="md-form mb-0">
                    <input
                      type="text"
                      id="subject"
                      name="subject"
                      className="form-control"
                      value={inputData.subject}
                      onChange={(e) =>
                        setInputData({ ...inputData, subject: e.target.value })
                      }
                    />
                    <label htmlFor="subject" className="">
                      Subject
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="md-form">
                    <textarea
                      type="text"
                      id="message"
                      name="message"
                      rows="2"
                      className="form-control md-textarea"
                      value={inputData.msg}
                      onChange={(e) =>
                        setInputData({ ...inputData, msg: e.target.value })
                      }
                    ></textarea>
                    <label htmlFor="message">Your message</label>
                  </div>
                </div>
              </div>
            </form>

            <div className="text-center text-md-left">
              <button
                type="submit"
                className="btn btn-primary  "
                onClick={(e) => handleSend(e)}
                style={{
                  background: "#3a9ad6",
                  border: "none",
                  overflow: "hidden",
                  height: "45px",
                }}
              >
                {loading ? (
                  <div
                    style={{
                      position: "relative",
                      // left: "50%",
                      width: "100px",
                      zIndex: "999",
                      top: "-70%",
                      // height: "45px",
                    }}
                  >
                    <lottie-player
                      // className="wow animate__animated animate__backInRight"
                      src="https://lottie.host/96c1b886-87c6-4cc5-999e-4360320ecf2a/0oTQR8dtOK.json"
                      background="transparent"
                      speed=".5"
                      // style="height: 60%;"
                      style={{ height: "30%" }}
                      loop
                      autoplay
                    ></lottie-player>
                  </div>
                ) : (
                  "Send message"
                )}
              </button>
            </div>
            <div className="status"></div>
          </div>

          <div className="col-md-3 text-center">
            <ul className="list-unstyled mb-0">
              <li>
                <i className="fas fa-map-marker-alt fa-2x"></i>
                <p>Balasore, 756001, INDIA</p>
              </li>

              <li>
                <i className="fas fa-phone mt-4 fa-2x"></i>
                <p>+91 8280799142</p>
              </li>

              <li>
                <i className="fas fa-envelope mt-4 fa-2x"></i>
                <p>looktaza.service@gmail.com</p>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactDetails;
