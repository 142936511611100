import { useEffect, useRef } from "react";

export default function useOnClickOutsideRef(callback, initialRef = null) {
  const elementRef = useRef(initialRef);
  useEffect(() => {
    function handler(event) {
      if (!elementRef.current?.contains(event.target)) {
        callback();
      }
    }
    window.addEventListener("click", handler);
    return () => {
      window.removeEventListener("click", handler);
    };
  }, [callback]);
  return elementRef;
}
