import React, { useState } from "react";
import serviceName from "../configs/staticData";
import UserSearch from "./UserSearch";

function Sidebar() {
  const [keyName, setKeyName] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [showSearchList, setShowSearchList] = useState({
    searchName: true,
    searchLocation: true,
  });

  const handleSelect = (e) => {
    setSearchName(e);
  };

  const handleSearch = (e) => {
    setSearchName(e.target.value);
    const fiteredName = serviceName.serviceName.filter((nam) =>
      nam.toLowerCase().includes(searchName.toLowerCase())
    );
    setKeyName(fiteredName);
    // To show search reference list
    setShowSearchList({ searchName: true, searchLocation: false });
  };

  return (
    <div
      className="container-fluid"
      style={{ position: "sticky", left: "0", top: "0" }}
    >
      <div className="row">
        <div className="col-12 sidebar_div">
          <div className="mt-4">
            <UserSearch
              className="flex-column"
              searchBoxStyle="my-2 sidebar_search_box"
              searchBox="sidebar_search_btn"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
