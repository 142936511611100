import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { endPoints } from "../configs/AppConfig";
import { toast } from "react-toastify";
import axios from "axios";
import ServiceCard from "./ServiceCard";
import moment from "moment";
import CryptoJS from "crypto-js";
import wintWall from "../assets/images/wintWall.avif";

const EditPage = () => {
  const [cookies, setCookie] = useCookies(["user"]);
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const navigate = useNavigate();

  const handleDelete = async (e, id) => {
    e.preventDefault();
    const typeIs = window.confirm("Are you sure you want to delete!");

    if (typeIs) {
      const url = endPoints().deleteDataUrl;
      const fData = new FormData();
      fData.append("id", id);
      await axios
        .post(url, fData)
        .then((res) => {
          if (res.data == "success") {
            toast.error("Deleted successfully");
            fetchRelatedData();
          } else {
            toast.warn(res.data);
          }
        })
        .catch((err) => toast.error(err));
    }
  };

  const fetchRelatedData = async () => {
    const url = endPoints().editDataUrl;
    const fData = new FormData();
    fData.append("userId", cookies.userId && cookies.userId);

    setLoading(true);

    await axios
      .post(url, fData)
      .then((res) => {
        setLoading(false);
        // console.log(res);
        if (typeof res.data == "object") {
          setAllData(res.data);
          // toast.success(res.data);
          // console.log(res.data);
          // setImageIs(JSON.parse(res.data[0].image));
        } else {
          toast.warn(res.data);
        }
      })
      .catch((err) => toast.error(err));
  };

  const SECRET_KEY = "iamsatyaprasannawebdeveloperfromamerica";

  const loginAuth = async (token) => {
    const url = endPoints().authLoginUrl;
    const fData = new FormData();
    fData.append("token", token);
    fData.append("email", cookies?.user && cookies?.user);
    await axios
      .post(url, fData)
      .then((res) => {
        // console.log(res);
        if (res.data == "success") {
          // console.log("login true");
          if (cookies?.IsServiceProvider == true) {
            // console.log("true");
            fetchRelatedData();
          } else {
            navigate("/");
          }
        } else {
          navigate("/");
        }
      })
      .catch((err) => toast.error(err));
  };

  useEffect(() => {
    if (cookies?.secret) {
      const encrypted = cookies?.secret ? cookies.secret : "null";

      const decrypted = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
        CryptoJS.enc.Utf8
      );
      const token = JSON.parse(decrypted);
      loginAuth(token);
    } else {
      navigate("/");
    }
  }, []);

  // useEffect(() => {
  //   if (cookies?.user && cookies?.user !== "undefined") {
  //   } else {
  //     navigate("/");
  //   }

  //   if (cookies?.IsServiceProvider == true) {
  //     // console.log("true");
  //     fetchRelatedData();
  //   } else {
  //     navigate("/");
  //   }
  // }, []);

  return (
    <>
      <div className="container my-5">
        {loading && (
          <div
            style={{
              position: "absolute",
              left: "10%",
              // width: "100px",
              zIndex: "999",
              top: "40%",
              height: "10rem",
              width: "100%",
            }}
          >
            <lottie-player
              // className="wow animate__animated animate__backInRight"
              src="https://lottie.host/2a5b810a-f29d-463c-8ab6-5d61739be89f/lPyIzzbJDy.json"
              background="transparent"
              speed=".3"
              // style="height: 60%;"
              style={{ height: "80%" }}
              loop
              autoplay
            ></lottie-player>
          </div>
        )}
        <div className="col-lg-12 d-flex bg-secondar service_card_box  m-auto mt-4">
          {allData?.map((data) => {
            return (
              <div className="col-4  " key={data.id}>
                <ServiceCard
                  key={data.id}
                  userId={data.userId}
                  service={data.serviceName}
                  location={data.pointedLocation}
                  stateName={data.state}
                  postedOn={moment(data.date).format("LL")}
                  serviceDetails={data.description}
                  imgSrc={data.image ? JSON.parse(data.image) : wintWall}
                  IsServiceProvider={true}
                  idIs={data.id}
                  handleDelete={handleDelete}
                  allData={allData}
                  fetchRelatedData={fetchRelatedData}
                />
              </div>
            );
          })}

          {/* <div className="col">
            <div className="card">
              <img
                src="https://mdbcdn.b-cdn.net/img/new/standard/city/042.webp"
                className="card-img-top"
                alt="Palm Springs Road"
              />
              <div className="card-body">
                <h5 className="card-title">Card title</h5>
                <p className="card-text">
                  This is a longer card with supporting text below as a natural
                  lead-in to additional content. This content is a little bit
                  longer.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img
                src="https://mdbcdn.b-cdn.net/img/new/standard/city/043.webp"
                className="card-img-top"
                alt="Los Angeles Skyscrapers"
              />
              <div className="card-body">
                <h5 className="card-title">Card title</h5>
                <p className="card-text">
                  This is a longer card with supporting text below as a natural
                  lead-in to additional content.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img
                src="https://mdbcdn.b-cdn.net/img/new/standard/city/044.webp"
                className="card-img-top"
                alt="Skyscrapers"
              />
              <div className="card-body">
                <h5 className="card-title">Card title</h5>
                <p className="card-text">
                  This is a longer card with supporting text below as a natural
                  lead-in to additional content.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img
                src="https://mdbcdn.b-cdn.net/img/new/standard/city/046.webp"
                className="card-img-top"
                alt="Skyscrapers"
              />
              <div className="card-body">
                <h5 className="card-title">Card title</h5>
                <p className="card-text">
                  This is a longer card with supporting text below as a natural
                  lead-in to additional content. This content is a little bit
                  longer.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img
                src="https://mdbcdn.b-cdn.net/img/new/standard/city/050.webp"
                className="card-img-top"
                alt="Skyscrapers"
              />
              <div className="card-body">
                <h5 className="card-title">Card title</h5>
                <p className="card-text">
                  This is a longer card with supporting text below as a natural
                  lead-in to additional content. This content is a little bit
                  longer.
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default EditPage;
