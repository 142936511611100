import React, { useState } from "react";

function Accorian({ question, answer }) {
  const [showAnswerModal, setShowAnswerModal] = useState(false);
  const handleAnswerModal = () => {
    setShowAnswerModal(!showAnswerModal);
  };
  return (
    <div className="container-fluid ">
      <div className="row">
        <div className="col-12 accorian_main ">
          <div className=" accordian_question" onClick={handleAnswerModal}>
            Q. {question}
          </div>
          <div
            className={`accordian_answer ${
              showAnswerModal && "show_ans_accordian"
            } `}
          >
            Ans. {answer}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Accorian;
