import ActionNames from "../configs/ActionNames";

const defaultstate = {
  isFetching: false,
  providedServices: [],
  // id: "",
  // userId: "",
  // serviceName: "",
  // phoneNo: "",
  // pointedLocation: "",
  // files: [],
  // description: "",
  // address: {},
};

export default function serviceProvider(state = defaultstate, action) {
  switch (action.type) {
    case ActionNames.UPDATE_SERVICE:
      return { ...state, isFetching: true };

    case ActionNames.UPDATE_SERVICE_SUCCESS: {
      console.log(action.response, "reducer--");
      return { ...state, isFetching: false };
    }

    case ActionNames.UPDATE_SERVICE_FAILED:
      return { ...state, isFetching: false };

    default:
      return state;
  }
}
