import { toast } from "react-toastify";
import ActionNames from "../configs/ActionNames";

const defaultstate = {
  isFetching: false,
  services: [],
};

export default function services(state = defaultstate, action) {
  switch (action.type) {
    case ActionNames.FETCH_SERVICE:
      return { ...state, isFetching: true };

    case ActionNames.FETCH_SERVICE_SUCCESS: {
      console.log(action.response, "reducer--");
      if (action.response.length === 0) {
        toast.warn("No data found");
      }

      return { ...state, services: action.response, isFetching: false };
    }

    case ActionNames.FETCH_SERVICE_FAILED:
      return { ...state, isFetching: false };

    default:
      return state;
  }
}
