export default function callAPIMiddleware({ dispatch, getState }) {
  return (next) => (action) => {
    const {
      types,
      callAPI,
      shouldCallAPI = () => true,
      payload = {},
      afterSuccess,
    } = action;
    if (!types) {
      return next(action);
    }

    if (
      !Array.isArray(types) ||
      types.length !== 3 ||
      !types.every((type) => typeof type === "string")
    ) {
      throw new Error("Expected an array of three string types.");
    }

    if (typeof callAPI !== "function") {
      throw new Error("Expected callAPI to be a function.");
    }

    if (!shouldCallAPI(getState())) {
      return;
    }

    const [requestType, successType, failureType] = types;
    dispatch({
      payload,
      type: requestType,
    });
    return callAPI(getState()).then(
      (response) => {
        dispatch({
          payload,
          response: response.data,
          type: successType,
        });

        if (afterSuccess) {
          afterSuccess(response, dispatch);
        }
      },
      (error) =>
        dispatch({
          payload,
          error: { message: error.message, name: error.name },
          type: failureType,
        })
    );
  };
}
