import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { endPoints } from "../configs/AppConfig";

const ForgetPassword = () => {
  const [inputData, setInputData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputData.email) {
      toast.warn("Please put the email");
    } else if (inputData.password.length <= 5) {
      toast.error("Passwords must have 5 characters");
    } else if (
      !/[A-Z]/.test(inputData.password) &&
      !/[a-z]/.test(inputData.password) &&
      !/[!@#$%^&*(){}]/.test(inputData.password)
    ) {
      toast.error(
        "Passwords must have uppercase lowercase and special characters"
      );
    } else {
      const url = endPoints().forgetUrl;
      const fData = new FormData();

      fData.append("email", inputData.email);
      fData.append("password", inputData.password);

      setLoading(true);

      await axios
        .post(url, fData)
        .then((res) => {
          setLoading(false);

          if (res.data === "Success") {
            toast.success(
              `We have sent an email to ${inputData.email}, please check your email`
            );
            setInputData({
              email: "",
              password: "",
            });
          } else {
            toast.warn(res.data);
          }
        })
        .catch((err) => toast.error(err));
    }
  };
  return (
    <>
      <div className="container-fluid form_wall">
        <div className="row ">
          <div className="form_box m-auto col-lg-10 ">
            <div className="form_card ">
              <div className=" form_left"></div>
              <div className="form_right login_form_card">
                <form>
                  <div className="form-row">
                    <div className="form-group mt-2">
                      <label htmlFor="inputEmail4">Email *</label>
                      <input
                        type="email"
                        className="form-control"
                        id="inputEmail4"
                        placeholder="Email"
                        value={inputData.email}
                        onChange={(e) =>
                          setInputData({ ...inputData, email: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label htmlFor="inputPassword4">New password *</label>
                      <input
                        type="password"
                        className="form-control"
                        id="inputPassword4"
                        placeholder="New password"
                        value={inputData.password}
                        onChange={(e) =>
                          setInputData({
                            ...inputData,
                            password: e.target.value,
                          })
                        }
                      />
                    </div>

                    <Link to="/login" style={{ textDecoration: "none" }}>
                      {" "}
                      <div className="mt-2">Login</div>
                    </Link>
                  </div>

                  <div className=" d-flex justify-content-end">
                    <button
                      onClick={(e) => handleSubmit(e)}
                      className="btn btn-primary mt-5 "
                      style={{
                        background: "#3a9ad6",
                        border: "none",
                        overflow: "hidden",
                        height: "45px",
                      }}
                    >
                      {loading ? (
                        <div
                          style={{
                            position: "relative",
                            // left: "50%",
                            width: "100px",
                            zIndex: "999",
                            top: "-70%",
                            // height: "45px",
                          }}
                        >
                          <lottie-player
                            // className="wow animate__animated animate__backInRight"
                            src="https://lottie.host/96c1b886-87c6-4cc5-999e-4360320ecf2a/0oTQR8dtOK.json"
                            background="transparent"
                            speed=".5"
                            // style="height: 60%;"
                            style={{ height: "30%" }}
                            loop
                            autoplay
                          ></lottie-player>
                        </div>
                      ) : (
                        "Verify & Reset"
                      )}
                    </button>
                  </div>
                  <div>
                    <Link to="/signup">Create account</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
