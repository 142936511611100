import React, { useEffect, useState } from "react";
import "../assets/css/Home.css";
// import SVGIcon from "./SVGIcon";
// import blogNames from "../database/staticBlogName";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import serviceName from "../configs/staticData";

import UserSearch from "./UserSearch";
import { useCookies } from "react-cookie";
import { endPoints } from "../configs/AppConfig";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { fetchLocation } from "../actions";

import vid from "../assets/video/vid.mp4";
import { Helmet } from "react-helmet";

// import Header from "./Header";
// const responsive = {
//   superLargeDesktop: {
//     // the naming can be any, depends on you.
//     breakpoint: { max: 4000, min: 3000 },
//     items: 5,
//   },
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 4,
//   },
//   tablet: {
//     breakpoint: { max: 1024, min: 464 },
//     items: 4,
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 4,
//   },
// };
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 2, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 767, min: 1 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const LocationName = [
  "Balasore",
  "Bhubaneswar",
  "Bhadrak",
  "Jajpur",
  "Cuttack",
  "Puri",
  "Khordha",
  "Baripada",
];

const Home = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [recentSearches, setRecentSearches] = useState([]);
  const { isFetching, location: allLocation } = useSelector(
    (state) => state.locationService
  );

  // console.log(allLocation, "home---");
  const [cookies, setCookie] = useCookies(["user"]);
  // const [allLocation, setAllLocation] = useState([]);
  const [keyName, setKeyName] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [keyLocation, setKeyLocation] = useState([]);
  const [SearchLocation, setSearchLocation] = useState("");
  const [colorChange, setColorchange] = useState(false);
  // To show search reference list
  const [showSearchList, setShowSearchList] = useState({
    searchName: true,
    searchLocation: true,
  });
  const handleSearch = (e) => {
    setSearchName(e.target.value);
    const fiteredName = serviceName.serviceName.filter((nam) =>
      nam.toLowerCase().includes(searchName.toLowerCase())
    );
    setKeyName(fiteredName);
    // To show search reference list
    setShowSearchList({ searchName: true, searchLocation: false });
  };

  const handleSelect = (e) => {
    setSearchName(e);
  };

  const handleLocationSearch = (e) => {
    setSearchLocation(e.target.value);
    const fiteredLocation = allLocation.filter((nam) =>
      nam.location.toLowerCase().includes(SearchLocation.toLowerCase())
    );
    setKeyLocation(fiteredLocation);
    // To show search reference list
    setShowSearchList({ searchLocation: true, searchName: false });
  };

  const handlLocationeSelect = (e) => {
    setSearchLocation(e);
  };

  const handleCaraBox = (ServiceName) => {
    navigate(`/pages/en-ind/${ServiceName.toLowerCase()}/locationEnd/stateEnd`);
  };

  // const fetchLocation = async () => {
  //   const url = endPoints().getLocationUrl;

  //   await axios
  //     .get(url)
  //     .then((res) => {
  //       // console.log(res.data);
  //       setAllLocation(res.data);
  //     })
  //     .catch((err) => toast.error(err));
  // };
  const changeNavbarColor = () => {
    if (window.scrollY >= 120) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };

  // const unsplashUri = async () => {
  //   const url = "https://source.unsplash.com/200x200/?kitchen";
  //   const unsplashData = await axios.get(url);
  //   console.log(unsplashData, "unsplashData---");
  //   return unsplashData;
  // };
  // unsplashUri();

  window.addEventListener("scroll", changeNavbarColor);

  useEffect(() => {
    // fetchLocation();
    dispatch(fetchLocation());
    setRecentSearches(JSON.parse(localStorage.getItem("recentSearch")));
  }, []);
  return (
    <>
      {/* <Header /> */}
      <div className="container-fluid home_main ">
        <Helmet>
          <title>
            Discover Services - Your Classified Hub for Local Solutions
          </title>
          <meta
            name="description"
            content={`Discover and connect with local services on our classified platform. Post your services and find what you need in your community. Simplify your life with a click.`}
          />
        </Helmet>
        <div className="row home_top">
          <div className="col-12   home_banner ">
            <div className="banner_text col-s-12 col-m-8 col-lg-8">
              <div
                className="banner_head"
                style={
                  colorChange
                    ? {
                        position: "fixed",
                        zIndex: "1100",
                        top: "0",
                        width: "100%",
                        left: "0",
                        backgroundColor: "ghostwhite",
                        height: "12vh",
                      }
                    : {
                        position: "relative",
                        zIndex: "1000",
                      }
                }
              >
                <h2 className="homeText">
                  <span style={{ color: "#9400ff" }}>Let's </span> Explore{" "}
                  {colorChange && "Together"}
                </h2>
                <UserSearch allLocation={allLocation} />
              </div>
              <div className="banner_carouse">
                <Carousel
                  responsive={responsive}
                  infinite
                  autoPlay
                  autoPlaySpeed={6000}
                >
                  {serviceName.myServices?.map((sName, index) => {
                    return (
                      <div
                        className="cara"
                        onClick={() => handleCaraBox(sName.name)}
                        key={sName.id}
                        style={{
                          // backgroundImage: `url("https://source.unsplash.com/200x200/?${sName.name}")`,
                          backgroundImage: `url("${
                            sName.image
                              ? sName.image
                              : `https://source.unsplash.com/200x200/?${sName.name}`
                          }")`,
                        }}
                      >
                        {sName.name}
                      </div>
                    );
                  })}
                </Carousel>
              </div>
              <div>
                <p className="mt-4 display-4 " style={{ textAlign: "center" }}>
                  Unlock the power of seamless simplicity with our all-in-one
                  web application!
                </p>
              </div>
              {/* <div>
                <button className="sub_btn">submit</button>
                <button className="sub_bt">submit</button>
              </div> */}
            </div>
          </div>

          {/* <div className="col-6 bg-light">hii</div> */}
        </div>
      </div>
      <section className="the_cara_box">
        <div className="container">
          <h2 className="pb-4 pt-4 h3">Popular Search</h2>
          <Carousel
            responsive={responsive}
            infinite
            autoPlay
            autoPlaySpeed={6000}
          >
            {recentSearches?.length > 0 ? (
              recentSearches.map((sName, index) => {
                return (
                  <div
                    className="cara"
                    onClick={() => handleCaraBox(sName.recent)}
                    key={index}
                    style={{
                      // backgroundImage: `url("https://source.unsplash.com/200x200/?${sName.recent}")`,
                      backgroundImage: `url("${
                        serviceName.myServices?.filter(
                          (nameIs) => nameIs.name === sName.recent
                        )[0]?.image
                          ? serviceName.myServices?.filter(
                              (nameIs) => nameIs.name === sName.recent
                            )[0]?.image
                          : `https://source.unsplash.com/200x200/?${sName.recent}`
                      }")`,
                    }}
                  >
                    {sName.recent}
                  </div>
                );
              })
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100vw",
                }}
              >
                <div
                  className="cara mx-3"
                  onClick={() => handleCaraBox("tution teacher")}
                  style={{
                    backgroundImage: `url("https://source.unsplash.com/200x200/?tution teacher}")`,
                  }}
                >
                  Tution teacher
                </div>
                <div
                  className="cara mx-3"
                  onClick={() => handleCaraBox("room rent")}
                  style={{
                    backgroundImage: `url("https://source.unsplash.com/200x200/?room rent}")`,
                  }}
                >
                  Room rent
                </div>
                <div
                  className="cara mx-3"
                  onClick={() => handleCaraBox("hotel")}
                  style={{
                    backgroundImage: `url("https://source.unsplash.com/200x200/?hotel}")`,
                  }}
                >
                  Hotel
                </div>
                <div
                  className="cara mx-3"
                  onClick={() => handleCaraBox("ac service")}
                  style={{
                    backgroundImage: `url("https://source.unsplash.com/200x200/?ac service}")`,
                  }}
                >
                  AC service
                </div>
                <div
                  className="cara mx-3"
                  onClick={() => handleCaraBox("plumber")}
                  style={{
                    backgroundImage: `url("https://source.unsplash.com/200x200/?plumber}")`,
                  }}
                >
                  Plumber
                </div>
              </div>
            )}
          </Carousel>
        </div>
      </section>
      <div className="number_two">
        <div className="container">
          <div className="left_two">
            <h1 className="lt_text_secondary">Looktaza is all about you</h1>

            <h3>Create your free post quickly and show worldwide</h3>
            <p>
              Looktaza is the platform for buying and selling a wide range of
              products and services. Whether you're a business owner looking to
              promote your offerings or an individual seeking to find the best
              deals, Looktaza has you covered.
            </p>
            <ul>
              <li>Just create your post </li>
              <li>Target your location </li>
              <li>Get leads </li>
            </ul>
          </div>
          <div className="right_two">
            {/* <img src={tv} alt="tv"></img> */}
            <div className="image_box">
              <video
                className="myVid"
                autoPlay="autoplay"
                loop="loop"
                muted
                // playsInline
                defaultmuted="true"
                // onContextMenu="return false;"
                preload="auto"
              >
                <source src={vid} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>

      <div className="row p-0 m-0">
        {/* <div className="col-lg-8  m-auto show_card">
          <p>Popular serches</p>
          <div className="bg-dange d-flex home_card">
            <div className="bg-secondary mx-1 card_one">kk</div>
            <div className="bg-secondary mx-1 card_two">kk</div>
            <div className="bg-secondary mx-1 card_three">kk</div>
          </div>
        </div> */}
        <div className="col-lg-12  home_decond d-flex justify-content-center ">
          <div className="col-lg-8 m-aut ">
            <p className="about_name">
              About <span className="lt_text_secondary">looktaza</span>{" "}
            </p>
            <div className="col-s-12 col-lg-12  m-aut   second_home_box">
              <div className="style_ball"></div>
              <div className="style_ball2"></div>
              {/* <div className="style_ball3"></div> */}
              <div className="about_content   col-s-">
                <h5>Brief details about Looktaza </h5>
                Looktaza is a marketplace to business with independent
                professionals and agencies around your area. In this site you
                will get some professionals and agencies who has resistrated
                there skills and services which will helps you to fulfil your
                requirment.Users, after getting there satisfactory
                requirmentwhile searching can directly contact with them.
              </div>
              <div className="about_content col-lg-">
                <h5>How to list your business </h5>
                Listing your business on Looktaza is a straightforward and
                effective way to showcase your products and services to a wide
                online audience. Start by creating an account or logging in,
                then navigate to the "List Your Business" section. Choose the
                most relevant category for your business, provide detailed
                information, upload captivating images, set your pricing and
                payment options, and include any additional details that make
                your business stand out. Once your listing is ready, hit the
                "Publish" button, and your business will be visible to potential
                customers. Don't forget to monitor and update your listing as
                needed to keep your information current and engaging for users.
                With Looktaza, you can take a significant step toward expanding
                your reach and connecting with a broader customer base.
              </div>
              <div className="about_content col-lg-">
                <h5>
                  Discover Looktaza: Your Ultimate Classifieds Destination{" "}
                </h5>
                Welcome to Looktaza, your ultimate destination for classified
                listings. Looktaza is the go-to platform for buying and selling
                a wide range of products and services. Whether you're a business
                owner looking to promote your offerings or an individual seeking
                to find the best deals, Looktaza has you covered. Our
                user-friendly interface and extensive categories make it easy
                for you to list and discover items that cater to your needs. As
                a seller, you can utilize Looktaza to reach a vast online
                audience, while buyers can explore an array of options. Join the
                Looktaza community today and experience the convenience and
                opportunities that our platform offers.
              </div>
              <div className="about_content col-lg-">
                <h5>Why Advertise on Looktaza? </h5>
                Are you a business owner looking to expand your market reach and
                increase your sales? Looktaza is the answer you've been
                searching for. With Looktaza, you can effectively promote your
                products and services to a diverse online audience. We offer an
                array of features and tools that enable you to create compelling
                listings, capture attention, and connect with potential
                customers. Our platform provides the visibility and exposure
                your business needs to thrive in the digital age. By advertising
                on Looktaza, you tap into a vast pool of prospective clients
                actively searching for products and services like yours. Join
                the ranks of successful businesses who have harnessed the power
                of Looktaza to grow their brand and boost their sales. Start
                your journey to success with Looktaza today!
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
