import { useEffect, useState } from "react";
import serviceName from "../configs/staticData";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { endPoints } from "../configs/AppConfig";
import axios from "axios";
import CryptoJS from "crypto-js";
// import country from "../configs/staticData";
// console.log(serviceName.country, "country");
const ProviderForm = () => {
  const [cookies] = useCookies(["user"]);

  const [inputData, setInputData] = useState({
    serviceName: "",
    phoneNumber: "",
    address: "",
    pin: "",
    showPhone: "no",
    targetLocation: "",
    description: "",
  });

  const [countryName, setCountryName] = useState("");
  const [stateName, setStateName] = useState("");
  const [loading, setLoading] = useState(false);
  // const [showImage, setShowImage] = useState(null);
  const [webpImage, setWebpImage] = useState([]);
  const navigate = useNavigate();
  const handelCountry = (e) => {
    setCountryName(e.target.value);
  };
  const handelState = (e) => {
    setStateName(e.target.value);
  };
  // const hh = serviceName.country?.filter((cName) =>
  //   cName.countryName?.includes(countryName)
  // );

  /**
   * Convert between any image formats the browser supports
   * @param {Blob} source A Blob (or File) containing the image to convert
   * @param {string} type The MIME type of the target format
   * @returns {Promise<Blob>} The converted image
   */

  const handleImage = async (event) => {
    // if (event.target.files && event.target.files[0]) {
    //   setShowImage(URL.createObjectURL(event.target.files[0]));
    // }
    if (webpImage.length < 5) {
      if (event.target.files && event.target.files[0]) {
        setLoading(true);
        let src = URL.createObjectURL(event.target.files[0]);

        let canvas = document.createElement("canvas");
        let context = canvas.getContext("2d");

        let imageDimension = await createImageBitmap(event.target.files[0]);

        let userImage = new Image();
        userImage.src = src;

        userImage.onload = function () {
          canvas.width = imageDimension.width;
          canvas.height = imageDimension.height;

          context.drawImage(userImage, 0, 0);

          let getWebPImage = canvas.toDataURL("image/webp");
          setWebpImage([...webpImage, getWebPImage]);
          setLoading(false);
        };
      }
    } else {
      toast.warn("You can add maximum 5 images");
    }

    //   let result = await new Promise((resolve, reject) => {
    //     canvas.toBlob((blob) => {
    //         if (blob != null) {
    //             resolve(blob);
    //         } else {
    //             reject(new Error("Failed to convert file"));
    //         }
    //     }, type, 1);
    // });

    // userImage.close();
    // return result;
  };

  const handleImageRemove = (imgFile) => {
    // console.log("hoiii");
    const filterImg = webpImage.filter((file) => file !== imgFile);
    setWebpImage(filterImg);
  };

  // console.log(cookies.userId);
  const handleLocation = async () => {
    const url = endPoints().locationUrl;
    const fData = new FormData();
    fData.append("location", inputData.targetLocation.toLocaleLowerCase());
    fData.append("state", stateName.toLocaleLowerCase());
    fData.append(
      "service",
      JSON.stringify([inputData.serviceName.toLocaleLowerCase()])
    );

    await axios
      .post(url, fData)
      .then((res) => {
        if (res.data === "success") {
          // console.log(res.data);
        } else {
          // console.log(res.data);
          const servicesAre = JSON.parse(res.data[0].service);
          const isServiceSame = servicesAre.find(
            (data) => data === inputData.serviceName.toLocaleLowerCase()
          );
          // console.log(isServiceSame);
          if (isServiceSame === undefined) {
            // console.log("in");

            servicesAre.push(
              inputData.serviceName.toLocaleLowerCase().toString()
            );
            // console.log(servicesAre);
            const url = endPoints().locationUpdateUrl;
            const fData = new FormData();
            fData.append(
              "locationIs",
              inputData.targetLocation.toLocaleLowerCase()
            );
            fData.append("serviceIs", JSON.stringify(servicesAre));
            axios
              .post(url, fData)
              .then((res) => {
                if (res.data === "update") {
                  // console.log("Successfully update");
                }
              })
              .catch((err) => toast.error(err));
          }
        }
      })
      .catch((err) => toast.error(err));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !inputData.serviceName ||
      !inputData.phoneNumber ||
      !inputData.address ||
      !inputData.pin ||
      !inputData.targetLocation ||
      !countryName ||
      !stateName
    ) {
      toast.warn("All fields are required");
    } else {
      const url = endPoints().uploadUrl;
      const fData = new FormData();
      fData.append("serviceName", inputData.serviceName.toLocaleLowerCase());
      fData.append("phoneNumber", inputData.phoneNumber);
      fData.append("address", inputData.address.toLocaleLowerCase());
      fData.append("pin", inputData.pin);
      fData.append("description", inputData.description);
      fData.append("showPhone", inputData.showPhone);
      fData.append(
        "targetLocation",
        inputData.targetLocation.toLocaleLowerCase()
      );
      fData.append("webpImage", JSON.stringify(webpImage));
      fData.append("countryName", countryName.toLocaleLowerCase());
      fData.append("stateName", stateName.toLocaleLowerCase());
      fData.append("userId", cookies?.userId);

      setLoading(true);

      await axios
        .post(url, fData)
        .then((res) => {
          setLoading(false);
          setInputData({
            serviceName: "",
            phoneNumber: "",
            address: "",
            pin: "",
            targetLocation: "",
            description: "",
          });
          setCountryName("");
          setStateName("");
          setWebpImage([]);

          toast.success(res.data);
          if (res.data === "success") {
            handleLocation();
          }
        })
        .catch((err) => toast.error(err));
    }
  };
  const SECRET_KEY = "iamsatyaprasannawebdeveloperfromamerica";

  const loginAuth = async (token) => {
    const url = endPoints().authLoginUrl;
    const fData = new FormData();
    fData.append("token", token);
    fData.append("email", cookies?.user && cookies?.user);
    await axios
      .post(url, fData)
      .then((res) => {
        if (res.data == "success") {
          // console.log("login true");
          if (cookies?.IsServiceProvider == true) {
            // console.log("true");
          } else {
            navigate("/");
          }
        } else {
          navigate("/");
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err);
      });
  };

  useEffect(() => {
    // console.log(cookies?.secret);
    if (cookies?.secret && cookies?.secret != "undefined") {
      const encrypted = cookies?.secret ? cookies.secret : "null";

      const decrypted = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
        CryptoJS.enc.Utf8
      );

      const token = JSON.parse(decrypted);
      loginAuth(token);
    } else {
      toast.warn("Please Login to provide service");
      navigate("/");
    }
  }, []);

  // useEffect(() => {
  //   if (cookies?.user && cookies?.user !== "undefined") {
  //   } else {
  //     navigate("/");
  //   }

  //   if (cookies?.IsServiceProvider == true) {
  //     // console.log("true");
  //   } else {
  //     navigate("/");
  //   }
  // }, []);
  console.log(serviceName);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="form_box m-auto col-lg-10 ">
            <div className="form_card">
              {/* <div className=" form_left"></div> */}
              <div className="form_right " style={{ width: "100%" }}>
                <form>
                  <div className="form-row">
                    <div className="mt-3">
                      <label htmlFor="inputType">Service name *</label>

                      <select
                        name="Choose"
                        id="inputType"
                        className=" mt-2 form-control"
                        value={inputData.serviceName}
                        onChange={(e) =>
                          setInputData({
                            ...inputData,
                            serviceName: e.target.value,
                          })
                        }
                      >
                        {serviceName.myServices.map((sName, index) => {
                          return (
                            <option key={index} value={sName.name} className="">
                              {sName.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="form-group mt-2">
                      <label htmlFor="inputEmail4">Phone number </label>
                      <input
                        type="number"
                        className="form-control"
                        // id="inputEmail4"
                        placeholder="Phone"
                        value={inputData.phoneNumber}
                        onChange={(e) =>
                          setInputData({
                            ...inputData,
                            phoneNumber: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div className="form-group mt-3">
                      <label htmlFor="inputAddress">Adress</label>
                      <input
                        type="text"
                        className="form-control"
                        // id="inputAddress"
                        placeholder="Name"
                        value={inputData.address}
                        onChange={(e) =>
                          setInputData({
                            ...inputData,
                            address: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="form-group col-5 mt-3">
                        <label htmlFor="inputAddress">Country</label>
                        <select
                          name="Choose"
                          id="inputType"
                          className=" mt-2 form-control"
                          onChange={handelCountry}
                        >
                          <option value=""></option>
                          {serviceName.country.map((sName, index) => {
                            return (
                              <option
                                key={index}
                                value={sName.countryName}
                                onChange={handelCountry}
                              >
                                {sName.countryName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group col-5 mt-3">
                        <label htmlFor="inputAddress">State</label>
                        <select
                          name="Choose"
                          id="inputType"
                          className=" mt-2 form-control"
                          onChange={handelState}
                        >
                          <option value=""></option>
                          {countryName &&
                            serviceName.country
                              ?.filter((cName) =>
                                cName.countryName?.includes(countryName)
                              )[0]
                              .state.map((stName, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={stName}
                                    onChange={handelCountry}
                                  >
                                    {stName}
                                  </option>
                                );
                              })}
                        </select>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="form-group col-5 mt-3">
                        <label htmlFor="inputAddress">Pin code</label>
                        <input
                          type="text"
                          className="form-control"
                          // id="inputAddress"
                          value={inputData.pin}
                          onChange={(e) =>
                            setInputData({
                              ...inputData,
                              pin: e.target.value,
                            })
                          }
                          placeholder="Pin"
                        />
                      </div>
                      <div className="form-group col-5 mt-3">
                        <label htmlFor="inputAddresss">
                          Want to show your phone number?
                        </label>
                        <select
                          class="form-select"
                          id="inputAddresss"
                          aria-label="Default select example"
                          value={inputData.showPhone}
                          onChange={(e) =>
                            setInputData({
                              ...inputData,
                              showPhone: e.target.value,
                            })
                          }
                        >
                          <option value="no" selected>
                            No
                          </option>
                          <option value="yes">Yes</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group  mt-3">
                      <label htmlFor="inputAddress">Target location</label>
                      <input
                        type="text"
                        className="form-control"
                        value={inputData.targetLocation}
                        onChange={(e) =>
                          setInputData({
                            ...inputData,
                            targetLocation: e.target.value,
                          })
                        }
                        // placeholder="Country"
                      />
                    </div>

                    <div className="form-group  mt-3">
                      <label htmlFor="inputAddress">Add image</label>
                      <input
                        type="file"
                        className="form-control"
                        accept="image/*"
                        multiple
                        onChange={handleImage}
                      />
                    </div>
                    <div className="uploaded_image">
                      {/* <img src={showImage} alt="" /> */}
                      {/* <img src={webpImage} alt="" /> */}
                      {webpImage.map((imgFile, index) => {
                        return (
                          <div key={index} className="uploaded_image_box">
                            <img loading="lazy" src={imgFile} alt="Loading" />
                            <div
                              onClick={() => handleImageRemove(imgFile)}
                              className="remove_btn"
                            >
                              Remove
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="form-group d-flex flex-column  mt-3">
                      <label htmlFor="inputAddress">Description</label>
                      {/* <input
                        type="textarea"
                        className="form-control"
                        // id="inputAddress"
                        placeholder="Add details"
                      /> */}
                      <textarea
                        maxLength="10000"
                        style={{ height: "5rem" }}
                        value={inputData.description}
                        onChange={(e) =>
                          setInputData({
                            ...inputData,
                            description: e.target.value,
                          })
                        }
                      ></textarea>
                    </div>
                  </div>

                  <div className=" d-flex justify-content-end">
                    <button
                      type="submit"
                      className="btn btn-primary mt-5 "
                      onClick={(e) => handleSubmit(e)}
                      style={{
                        background: "#3a9ad6",
                        border: "none",
                        overflow: "hidden",
                        height: "45px",
                      }}
                    >
                      {loading ? (
                        <div
                          style={{
                            position: "relative",
                            // left: "50%",
                            width: "100px",
                            zIndex: "999",
                            top: "-70%",
                            // height: "45px",
                          }}
                        >
                          <lottie-player
                            // className="wow animate__animated animate__backInRight"
                            src="https://lottie.host/96c1b886-87c6-4cc5-999e-4360320ecf2a/0oTQR8dtOK.json"
                            background="transparent"
                            speed=".5"
                            // style="height: 60%;"
                            style={{ height: "30%" }}
                            loop
                            autoplay
                          ></lottie-player>
                        </div>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProviderForm;
