import React, { useCallback, useEffect, useState } from "react";
import useOnClickOutsideRef from "../hooks/useOnClickOutsideRef";
import { useNavigate } from "react-router-dom";
import serviceName from "../configs/staticData";
import { FaSearch } from "react-icons/fa";

const LocationName = [
  "Balasore",
  "Bhubaneswar",
  "Bhadrak",
  "Jajpur",
  "Cuttack",
  "Puri",
  "Khordha",
  "Baripada",
];
const UserSearch = ({ className, searchBoxStyle, searchBox, allLocation }) => {
  let navigate = useNavigate();

  const [keyName, setKeyName] = useState([]);
  const [recentSearches, setRecentSearches] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [keyLocation, setKeyLocation] = useState([]);
  const [SearchLocation, setSearchLocation] = useState("");
  const [stateIs, setStateIs] = useState("");
  // To show search reffirste list
  const [showSearchList, setShowSearchList] = useState({
    searchName: false,
    searchLocation: false,
  });
  const searchRef = useOnClickOutsideRef(
    useCallback(() =>
      setShowSearchList({ searchName: false, searchLocation: false })
    )
  );
  // console.log(serviceName);

  const handleSearch = (e) => {
    setSearchName(e.target.value);
    const fiteredName = serviceName.myServices.filter((nam) => {
      return nam.name.toLowerCase().includes(e.target.value.toLowerCase());
    });
    setKeyName(fiteredName);
    // To show search reference list
    setShowSearchList({ searchName: true, searchLocation: false });
  };

  const handleSelect = (e) => {
    setSearchName(e);
  };

  const handleLocationSearch = (e) => {
    setSearchLocation(e.target.value);
    const fiteredLocation = allLocation.filter((nam) =>
      nam.location.toLowerCase().includes(e.target.value.toLowerCase())
    );

    setKeyLocation(fiteredLocation);
    // To show search reference list
    setShowSearchList({ searchLocation: true, searchName: false });
  };

  const handlLocationeSelect = (e, state) => {
    setSearchLocation(e);
    setStateIs(state);
  };

  const handelRecentSearch = (newData) => {
    const preveiousData = JSON.parse(localStorage.getItem("recentSearch"));

    const isPresent = preveiousData?.filter((data) => data.recent === newData);

    if (preveiousData?.length > 5) {
      const settingLimit = preveiousData?.filter(
        (spl) => spl.recent != preveiousData[0].recent
      );

      if (isPresent === undefined || isPresent?.length === 0) {
        setRecentSearches([...settingLimit, { recent: newData }]);
        localStorage.setItem(
          "recentSearch",
          preveiousData
            ? JSON.stringify([...settingLimit, { recent: newData }])
            : JSON.stringify([{ recent: newData }])
        );
      }
    } else {
      if (isPresent === undefined || isPresent?.length === 0) {
        localStorage.setItem(
          "recentSearch",
          preveiousData
            ? JSON.stringify([...preveiousData, { recent: newData }])
            : JSON.stringify([{ recent: newData }])
        );

        if (recentSearches === null) {
          setRecentSearches([{ recent: newData }]);
        } else {
          setRecentSearches([...recentSearches, { recent: newData }]);
        }
      }
    }
  };

  const handleRemoveRecent = (name) => {
    const preveiousData = JSON.parse(localStorage.getItem("recentSearch"));
    const removeingData = preveiousData.filter((data) => data.recent != name);
    localStorage.setItem("recentSearch", JSON.stringify(removeingData));
    setRecentSearches(removeingData);
  };

  useEffect(() => {
    setKeyLocation(allLocation);
    setKeyName(serviceName.myServices);
  }, [allLocation]);

  useEffect(() => {
    setRecentSearches(JSON.parse(localStorage.getItem("recentSearch")));
  }, []);
  return (
    <>
      <div>
        <div
          className={` banner_input d-flex user_search ${className}  `}
          ref={searchRef}
        >
          <div className={`position-relative ${searchBoxStyle}`}>
            {/* <input
              placeholder="All categories"
              value={searchName}
              type="text"
              onChange={handleSearch}
            /> */}
            <div className="input__container">
              {/* <div className="shadow__input"></div> */}
              {/* <button class="input__button__shadow"></button> */}
              <input
                type="text"
                autocomplete="off"
                value={searchName}
                onChange={handleSearch}
                name="text"
                className="input__search"
                placeholder="Find service here"
                onClick={() =>
                  setShowSearchList({ searchLocation: false, searchName: true })
                }
              />
            </div>
            {showSearchList.searchName && (
              <div className="search_box">
                {recentSearches && (
                  <h6 style={{ color: "black", fontWeight: "650" }}>
                    Recent search
                  </h6>
                )}
                {recentSearches &&
                  recentSearches?.map((nam, index) => {
                    return (
                      <h6 key={index} onClick={() => handleSelect(nam.recent)}>
                        {nam.recent}{" "}
                        <span
                          onClick={() => handleRemoveRecent(nam.recent)}
                          style={{ position: "absolute", right: "10px" }}
                        >
                          x
                        </span>
                      </h6>
                    );
                  })}
                <hr />
                {keyName && (
                  <h6 style={{ color: "black", fontWeight: "650" }}>
                    Categories
                  </h6>
                )}
                {keyName?.map((nam, index) => {
                  return (
                    <h6 key={index} onClick={() => handleSelect(nam.name)}>
                      {nam.name}
                    </h6>
                  );
                })}
              </div>
            )}
          </div>
          <div className={`position-relative  ${searchBoxStyle}`}>
            {/* <input
              placeholder="Location"
              type="text"
              value={SearchLocation}
              onChange={handleLocationSearch}
            /> */}
            <div className="input__container">
              {/* <div className="shadow__input"></div> */}
              {/* <button class="input__button__shadow"></button> */}

              <input
                type="text"
                name="text"
                autocomplete="off"
                className="input__search ext"
                placeholder="Location"
                value={SearchLocation}
                onChange={handleLocationSearch}
                onClick={() =>
                  setShowSearchList({ searchLocation: true, searchName: false })
                }
              />
            </div>
            {showSearchList.searchLocation && (
              <div className="search_box">
                {keyLocation.map((nam, index) => {
                  return (
                    <h6
                      key={index}
                      onClick={() =>
                        handlLocationeSelect(nam.location, nam.state)
                      }
                    >
                      {nam.location}({nam.state})
                    </h6>
                  );
                })}
              </div>
            )}
          </div>
          <div
            className={` IamSearch ${searchBox}`}
            onClick={() => {
              (searchName || SearchLocation) &&
                navigate(
                  `/pages/en-ind/${searchName || "nameEnd"}/${
                    SearchLocation || "locationEnd"
                  }/${stateIs || "stateEnd"}`
                );
              setShowSearchList({ searchLocation: false, searchName: false });
              handelRecentSearch(searchName);
            }}
          >
            {window.innerWidth <= 768 ? <FaSearch /> : "Search"}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserSearch;
