import ActionNames from "../configs/ActionNames";

const defaultstate = {
  isFetching: false,
  location: [],
};

export default function locationService(state = defaultstate, action) {
  switch (action.type) {
    case ActionNames.FETCH_LOCATION:
      return { ...state, isFetching: true };

    case ActionNames.FETCH_LOCATION_SUCCESS: {
      // console.log(action.response, "reducer--");
      return { ...state, location: action.response, isFetching: false };
    }

    case ActionNames.FETCH_LOCATION_FAILED:
      return { ...state, isFetching: false };

    default:
      return state;
  }
}
