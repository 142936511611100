import React, { useState } from "react";
import { toast } from "react-toastify";
import { endPoints } from "../configs/AppConfig";
import * as LottiePlayer from "@lottiefiles/lottie-player";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function Signup() {
  const navigate = useNavigate();
  const [inputData, setInputData] = useState({
    name: "",
    email: "",
    password: "",
    cpassword: "",
    IsServiceProvider: "",
    checkBox: false,
  });
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !inputData.name ||
      !inputData.email ||
      !inputData.IsServiceProvider ||
      !inputData.password ||
      !inputData.cpassword
    ) {
      toast.warn("All fields are required");
    } else if (inputData.password !== inputData.cpassword) {
      toast.error("Passwords donot match");
    } else if (inputData.password.length <= 5) {
      toast.error("Passwords must have 5 characters");
    } else if (
      !/[A-Z]/.test(inputData.password) &&
      !/[a-z]/.test(inputData.password) &&
      !/[!@#$%^&*(){}]/.test(inputData.password)
    ) {
      toast.error(
        "Passwords must have uppercase lowercase and special characters"
      );
    } else if (inputData.checkBox === false) {
      toast.error("Please click on the check box");
    } else {
      const url = endPoints().userSignupUrl;
      const fData = new FormData();
      fData.append("name", inputData.name);
      fData.append("email", inputData.email);
      fData.append("IsServiceProvider", inputData.IsServiceProvider);
      fData.append("password", inputData.password);

      setLoading(true);

      await axios
        .post(url, fData)
        .then((res) => {
          setLoading(false);

          if (res.data === "Success") {
            toast.success(
              `We have sent an email to ${inputData.email}, please activate your account`
            );
            setInputData({
              name: "",
              email: "",
              IsServiceProvider: "",
              password: "",
              cpassword: "",
            });
            navigate("/login");
          } else {
            toast.warn(res.data);
          }
        })
        .catch((err) => toast.error(err));
    }
  };

  return (
    <div className="container-fluid form_wall">
      <div className="row ">
        <div className="form_box m-auto col-lg-10 ">
          <div className="form_card">
            <div className=" form_left"></div>
            <div className="form_right">
              <form>
                <div className="form-row">
                  <div className="form-group mt-3">
                    <label htmlhtmlFor="inputAddress">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      // id="inputAddress"
                      placeholder="Example exp"
                      value={inputData.name}
                      onChange={(e) =>
                        setInputData({ ...inputData, name: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label htmlhtmlFor="inputEmail4">Email *</label>
                    <input
                      type="email"
                      className="form-control"
                      id="inputEmail4"
                      placeholder="examle@gmail.com"
                      value={inputData.email}
                      onChange={(e) =>
                        setInputData({ ...inputData, email: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label htmlhtmlFor="inputPassword4">Password *</label>
                    <input
                      type="password"
                      className="form-control"
                      id="inputPassword4"
                      placeholder="Password"
                      value={inputData.password}
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          password: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label htmlhtmlFor="inputPassword43">
                      Confirm Password *
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="inputPassword43"
                      placeholder="Confirm Password"
                      value={inputData.cpassword}
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          cpassword: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="mt-3">
                  <label htmlhtmlFor="inputType">Select option *</label>

                  <select
                    name="Choose"
                    id="inputType"
                    className=" mt-2 form-control"
                    value={inputData.IsServiceProvider}
                    onChange={(e) =>
                      setInputData({
                        ...inputData,
                        IsServiceProvider: e.target.value,
                      })
                    }
                  >
                    <option value="">select</option>
                    <option value={true}>I want to provide service</option>
                    <option value={false}>I want to get service</option>
                  </select>
                </div>
                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    value={inputData.checkBox}
                    onChange={(e) =>
                      setInputData({
                        ...inputData,
                        checkBox: !inputData.checkBox,
                      })
                    }
                  />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    Check to agree <Link to="/term"> Terms </Link>&
                    <Link to="/policies"> Polices</Link>
                  </label>
                </div>
                <Link to="/login">Already have account?</Link>

                <div className=" d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-primary  "
                    onClick={(e) => handleSubmit(e)}
                    style={{
                      background: "#3a9ad6",
                      border: "none",
                      overflow: "hidden",
                      height: "45px",
                    }}
                  >
                    {loading ? (
                      <div
                        style={{
                          position: "relative",
                          // left: "50%",
                          width: "100px",
                          zIndex: "999",
                          top: "-70%",
                          // height: "45px",
                        }}
                      >
                        <lottie-player
                          // className="wow animate__animated animate__backInRight"
                          src="https://lottie.host/96c1b886-87c6-4cc5-999e-4360320ecf2a/0oTQR8dtOK.json"
                          background="transparent"
                          speed=".5"
                          // style="height: 60%;"
                          style={{ height: "30%" }}
                          loop
                          autoplay
                        ></lottie-player>
                      </div>
                    ) : (
                      "Sign in"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
