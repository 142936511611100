import actions from "../configs/ActionNames";

export function openSidebar(value) {
  return {
    type: actions.CLOSE_SIDEBAR,
    payload: value,
  };
}

export function hendleNavbar(value) {
  return {
    type: actions.HANDLE_NAV_BAR,
    payload: value,
  };
}
