import React from "react";

function Policies() {
  return (
    <>
      <body class="background background--light background--dark">
        <h2>Listing Policy</h2>
        <p>
          For use of our Site and other services, you confirm and declare that
          you shall not list or post or provide information in relation to the
          sale or purchase or exchange of goods and services, content or
          information that are illegal under the laws of the Republic of India
          and/or are not permitted as per the prohibited items policy listed
          below.
        </p>
        <h5>Prohibited Items Policy.</h5>
        <h6>
          We specifically prohibit any listing or posting of classifieds or
          information in relation to the following items:
        </h6>
        <p>
          • Alcoholic Beverages, Liquor, tobacco products, drugs, psychotropic
          substances, narcotics, intoxicants of any description, medicines,
          palliative/curative substances nor shall you provide link directly or
          indirectly to or include descriptions of items, goods or services that
          are prohibited under any applicable law for the time being in force
          including but not limited to the Drugs and Cosmetics Act, 1940, the
          Drugs And Magic Remedies (Objectionable Advertisements) Act, 1954
          Narcotic Drug and Prohibited Substances Act and the Indian Penal Code,
          1860.
        </p>
        <p>
          Living, dead person and/or the whole or any part of any human which
          has been kept or preserved by any means whether artificial or natural
          including any blood, bodily fluids and/ or body parts
        </p>
        <p>
          Prostitution or any other service in the nature there of that purports
          to violate the provisions of Immoral Act or Indecent representation of
          women which violates the contemporary standards of morality and
          decency in Indian society.
        </p>
        <p>
          Religious items, including books, artifacts, etc. or any information,
          description of any such item that is likely to affect the religious
          sentiments of any person or group
        </p>
        <p>
          Mature Audiences Policy includes films which do not have a certificate
          for public exhibition issued by the Central Board of Film
          Certification and or described and depict or otherwise deal with
          matters which are revolting or repulsive and or tend to deprave a
          persons mind in such a way that they tend to offend against the
          standards of morality, decency and propriety generally accepted by
          reasonable adults
        </p>
        <p>
          Obscene Items includes items which contain an indecent representation
          of women within the meaning of the Indecent Representation of Women
          (Prohibition) Act, 1986; Any publication or film or item that
          describes or depicts a minor who is, or who appears to be, under 18
          (whether the minor is engaged in sexual activity or not) and any
          computer games not suitable for minor that are unsuitable for a minor
          to see or play.
        </p>
        <p>
          Offensive Material intended for use in a sexual setting (including
          "bondage" and "fetish" items,) displaying sexual activity or
          portraying human genitalia in a "life-like" or realistic fashion
        </p>
        <p>
          "Antiquities" and "Art Treasures" in violation of the provisions of
          the Antiquities and Art Treasures Act, 1972 ("the Act")
        </p>
        <p>
          Information or items that are defamatory, libelous, threatening or
          abusive in nature
        </p>
        <p>
          Items, goods and services that infringe or attempt to pass off any
          third parties intellectual property or rights of publicity or moral
          rights and or purport's to breach any persons right to privacy.
        </p>
        <p>
          Information that is fraudulent, misrepresenting as to the nature and
          use of the goods or the services.
        </p>
        <p>
          Counterfeit, Pirated and stolen goods or unauthorized illegal services
          (services for which you are not licensed or permitted to do or do not
          have the authority to under take).
        </p>
        <p>
          Fireworks, Destructive Devices and Explosives including any material
          that enables the making of fireworks, explosive triggers and explosive
          devices.
        </p>
        <p>
          Electronically transmitting through any medium computer viruses of any
          type or any computer program that facilitates hacking of a computer
          system which the intent to damage a computer or computer network or
          intercept any personal data.
        </p>
        <p>
          Your information shall not include any hate content, that is
          derogatory or slanderous in nature that may directed to any individual
          or group or advocate violence against any users individuals and or
          animals.
        </p>
        <p>
          Hazardous chemicals and pesticides and/ or items in violation of
          Hazardous Chemicals Act, 1985.
        </p>
        <p>
          Identity Documents, Personal Financial Records & Personal Information
          (in any form, including mailing lists)
        </p>
        <p>Lottery Tickets, Sweepstakes Entries and Slot Machines</p>
        <p>Items in violation of the Food Adulteration Act, 1954</p>
        <p>
          Military or Police Badges, Uniforms, coats-of-arms and or any
          Government emblems, insignia, and/ or items in violation of Emblems
          and names (Prevention of improper use) Act, 1950 and/ or Flag Codes of
          India Act, 2002
        </p>
        <p>
          Weapons and related items (such as firearms, firearm parts and
          magazines, ammunition, tear gas, stun guns, switchblade knives or any
          other item which is prohibited under the Indian Arms Act, 1959.
        </p>
        <p>
          "Pyramid schemes" and "Multilevel Marketing" and/ or similar scams
          which are solely listed for the purpose of defrauding users.
        </p>
        <p>
          Spam, abusive, duplicate, listing, fraud schemes (e.g. "Get rich
          quick" "work at homes" scams which are solely listed for the purpose
          of duping users)
        </p>
        <p>
          Inappropriate, Wrong Category (e.g dining table listed as office
          furniture).
        </p>
        <p>
          Incorrect City / Location of Listing (listing allowed only in the city
          you are based in, of listing)
        </p>
        <p>
          belongs to another person and to which the user does not have any
          right;
        </p>
        <p>
          is defamatory, obscene, pornographic, pedophilic, invasive of
          another‘s privacy, including bodily privacy, insulting or harassing on
          the basis of gender, libellous, racially or ethnically objectionable,
          relating or encouraging money laundering or gambling, or otherwise
          inconsistent with or contrary to the laws in force;
        </p>
        <p>is harmful to children;</p>
        <p>
          infringes any patent, trademark, copyright or other proprietary
          rights;
        </p>
        <p>violates any law for the time being in force;</p>
        <p>
          deceives or misleads the addressee about the origin of the message or
          knowingly and intentionally communicates any information which is
          patently false or misleading in nature but may reasonably be perceived
          as a fact;
        </p>
        <p>
          contains software virus or any other computer code, file or program
          designed to interrupt, destroy or limit the functionality of any
          computer resource;
        </p>
        <p>impersonates another person;</p>
        <p>
          is patently false and untrue, and is written or published in any form,
          with the intent to mislead or harass a person, entity or agency for
          financial gain or to cause any injury to any person.
        </p>
        <p>
          threatens the unity, integrity, defence, security or sovereignty of
          India, friendly relations with foreign States, or public order, or
          causes incitement to the commission of any cognisable offence or
          prevents investigation of any offence or is insulting other nation;
        </p>

        <h6>
          Without prejudice to the generality of the above, Looktza does not
          permit posting or listing of classifieds in relation to the following:
        </h6>
        <p>
          "Securities" within the meaning of the Securities Contract Regulation
          Act, 1956, including shares, bonds, debentures, etc. and/or any other
          financial instruments/assets of any description
        </p>
        <p>
          Living, dead creatures and/or the whole or any part of any animal
          which has been kept or preserved by any means whether artificial or
          natural including rugs, skins, specimens of animals, antlers, horns,
          hair, feathers, nails, teeth, musk, eggs, nests, other animal products
          of any description the sale and purchase of which is prevented or
          restricted in any manner by applicable laws (including those
          prohibited under The Wildlife Protection Act, 1972 and/ or The
          Environment Protection Act, 1986)
        </p>
        <h6>Your listing, information, Advertisement</h6>
        <p>
          Shall not be defamatory, trade libelous, unlawfully threatening or
          unlawfully harassing. Further shall not be fraudulent,
          misrepresenting, misleading or pertain to the sale of any illegal,
          counterfeit, stolen goods and or services which do not belong to you
          or you do not have the authority for. Further still shall not infringe
          any intellectual property, trade secret, or other proprietary rights
          or rights of publicity or privacy of any third party.
        </p>
        <p>
          Shall not contain any viruses, Trojan horses, worms, time bombs,
          cancel bots, easter eggs or other computer programming routines that
          may damage, detrimentally interfere with, surreptitiously intercept or
          expropriate any system, data or personal information.
        </p>
        <p>
          Shall not be allowed to libel anyone or include hate, derogatory,
          slanderous speech directed at individuals or groups. You should not
          advocate violence against other users or individuals or groups.
        </p>
        <h6>
          In addition to the above and for the purposes of clarity all Users
          shall be expected to adhere to and comply with the following Policies
          while listing of items:
        </h6>
        <p>
          Restricted Item Policy: In addition to the above prohibited items
          policy users shall also adhere to and comply with the restricted items
          policy while listing, posting or providing information in relation to
          any goods or services.
        </p>
        <p>
          Duplicate Ad listings are not allowed. Any ad posted more than once
          with the same content or Title in the same city and category would be
          considered as a Duplicate Ad. We advise you to post multiple ads only
          if you have different items or services for sale. All duplicate ads
          would be deleted and posters penalized if the problem persists.
        </p>
        <p>
          Mature Audience/Sexually oriented material: Classifieds relating to
          items that includes items intended for use in sexual activity would
          not be permitted. (An example of such classifieds relating to an item
          not normally permitted would be a classified for the sale of a
          vibrator). Please also be aware that titles with graphic adult
          language are inappropriate, regardless of the item contained in the
          listing itself.
        </p>
        <h6>Details to be provided by the Seller:</h6>
        <p>Contractual information pertaining to the goods, if any.</p>
        <p>
          Total price of any good or service, along with the breakup price for
          the good or service, showing all the compulsory and voluntary charges
          and the applicable tax.
        </p>
        <p>
          All mandatory notices and information provided by applicable laws, and
          the expiry date of the good being offered for sale, where applicable.
        </p>
        <p>
          All relevant details about the goods and services offered including
          country of origin
        </p>
        <p>
          The name and contact numbers, and designation of the grievance officer
          of the Seller
        </p>
        <p>
          Name and details of importer in case of imported goods, and guarantees
          related to the authenticity or genuineness of the imported products
        </p>
        <p>Whether registered entity</p>
        <p>
          Terms of exchange, returns, and refund including information related
          to costs of return shipping.
        </p>
        <p>Physical address</p>
        <p>
          Relevant details related to delivery and shipment of such goods or
          services.
        </p>
        <p>
          Any relevant guarantees or warranties applicable to such goods or
          services.
        </p>
        <p>Rating/aggregated feedback</p>
        <p>
          Content is consistent with the actual characteristic, features of
          goods and services.
        </p>
        <h5>Consequences of Breach of Listing Policy</h5>
        <h6>
          Users who violate the prohibited items policy and or the restricted
          items policy may be subject to the following actions
        </h6>
        <p>Suspension or termination of membership.</p>
        <p>Permanent blocking of access to the site.</p>
        <p>Reporting to Law Enforcement or Appropriate Authorities.</p>
        <p>Action will be taken in accordance with the Terms of Use.</p>

        {/* <p>
          Privacy Policy created for ODOAN by{" "}
          <a
            href="https://humanassistai.com"
            title="Privacy Policy created for ODOAN"
          >
            HAI.
          </a>
        </p> */}
      </body>
    </>
  );
}

export default Policies;
