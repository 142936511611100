import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { endPoints } from "../configs/AppConfig";
import axios from "axios";
import { toast } from "react-toastify";
import "../assets/css/fullDetails.css";
import {
  FaClipboardList,
  FaEnvelope,
  FaPhoneAlt,
  FaUserAlt,
  FaWindowClose,
} from "react-icons/fa";
import moment from "moment";
import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet";

const FullDetails = () => {
  const [singleData, setSingleData] = useState([]);
  const [cookies, removeCookie] = useCookies(["user"]);

  const [providerDetails, setProviderDetails] = useState([]);
  const [choosedImge, setChoosedImge] = useState("");
  const [sendQuery, setSendQuery] = useState(false);
  const [loading, setLoading] = useState(false);
  // Form handling
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");

  const params = useParams();

  // console.log(JSON.parse(singleData.image).length);

  const fetchParticularData = async () => {
    const url = endPoints().particularDataUrl + `&mainId=${params.mainId}`;
    // apiClient.get(endPoints().cardDataUrl +`?_location=${params.location || 'locationEnd'}&_serviceProvider=${}`)
    await axios
      .get(url)
      .then((res) => {
        if (res.status === 200 && res.data) {
          setSingleData(res.data[0]);
        }
      })
      .catch((err) => toast.error(err));
  };
  // console.log(singleData);
  const fetchUserDetails = async () => {
    const url = endPoints().providerDataUrl + `&user=${params.providerId}`;

    // apiClient.get(endPoints().cardDataUrl +`?_location=${params.location || 'locationEnd'}&_serviceProvider=${}`)
    await axios
      .get(url)
      .then((res) => {
        if (res.status === 200 && res.data) {
          setProviderDetails(res.data[0]);
        }
      })
      .catch((err) => toast.error(err));
  };
  const handleQuery = () => {
    setSendQuery(!sendQuery);
    if (cookies?.user && cookies?.user !== "undefined") {
      setEmail(cookies.user && cookies.user);
      setName(cookies.userName && cookies.userName);
    } else {
      toast.warn("It looks like you are not logged in ");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || (!email && !phoneNo)) {
      toast.warn("Please fill all the field");
    } else {
      const url = endPoints().userContactToProviderUrl;
      const fData = new FormData();
      fData.append("name", name);
      fData.append("email", email);
      fData.append("phoneNo", phoneNo);
      fData.append("service", singleData.serviceName);
      fData.append("providerId", params.providerId);
      fData.append("serviceId", singleData.id);
      fData.append("toEmail", providerDetails.email);

      setLoading(true);

      await axios
        .post(url, fData)
        .then((res) => {
          setLoading(false);

          if (res.data === "Success") {
            setSendQuery(false);
            setName("");
            setEmail("");
            setPhoneNo("");
            toast.success(
              `Your query has been sent to ${providerDetails.name}, They will contact you soon...`
            );
          } else {
            toast.warn(res.data);
          }
        })
        .catch((err) => toast.error(err));
    }
  };

  useEffect(() => {
    fetchParticularData();
    fetchUserDetails();
  }, []);
  return (
    <>
      <div className="main_full">
        <Helmet>
          <title>
            {` ${singleData?.serviceName} | Your Ultimate Classified Marketplace`}
            {/* {singleData?.serviceName} | Your Ultimate Classified Marketplace */}
          </title>
          <meta
            name="description"
            content={`Discover endless possibilities on ${singleData.serviceName} – your one-stop classified marketplace for a wide array of services. Explore, connect, and find what you need effortlessly.`}
          />
        </Helmet>
        <div className="containerr">
          <div className="left_full">
            <div className="left_full_top">
              <img
                src={
                  singleData && singleData.image
                    ? choosedImge.length > 0
                      ? choosedImge
                      : JSON.parse(singleData.image)[0]
                    : ""
                }
                loading="lazy"
                alt={singleData && singleData.serviceName}
              />
              {singleData && singleData.image
                ? JSON.parse(singleData.image).length > 1 && (
                    <div className="ext_img_box">
                      {JSON.parse(singleData.image).map((data, index) => {
                        return (
                          <div
                            onClick={() => setChoosedImge(data)}
                            className="ext_img1"
                            key={index}
                          >
                            <img src={data} loading="lazy" alt="Loading" />
                          </div>
                        );
                      })}
                      {/* <div className="ext_img1"></div> */}
                    </div>
                  )
                : ""}
              {/* <div className="ext_img_box">
                <div className="ext_img1"></div>
                <div className="ext_img1"></div>
              </div> */}
            </div>
            <div className="left_full_bottom">
              <div className="left_full_bottom_left">
                <h1>{singleData && singleData.serviceName}</h1>
                <p>
                  {singleData && singleData.pointedLocation},
                  {singleData && singleData.state}
                </p>
                <h5>
                  <FaUserAlt />{" "}
                  <span> {providerDetails && providerDetails.name}</span>
                </h5>
                <h5 style={{ textTransform: "none" }}>
                  <FaEnvelope />{" "}
                  <span> {providerDetails && providerDetails.email}</span>
                </h5>
                <h5>
                  <FaPhoneAlt />{" "}
                  <span
                    style={
                      singleData.showPhone === "yes"
                        ? {
                            textShadow: "0 0 0px #000",
                            color: "transparent",
                            userSelect: "none",
                          }
                        : {
                            textShadow: "0 0 8px #000",
                            color: "transparent",
                            userSelect: "none",
                          }
                    }
                  >
                    {singleData.showPhone === "yes"
                      ? singleData.phoneNo
                      : "11111111🤣"}
                  </span>
                </h5>

                <button
                  onClick={() => {
                    navigator.clipboard.writeText(window.location.href);
                    toast.success("Url copied to clipboard");
                  }}
                  className="btn btn-secondary"
                >
                  Copy URL to clipboard <FaClipboardList />
                </button>
              </div>
              <div className="left_full_bottom_right_ll">
                <h4>Address</h4>
                <p>
                  {singleData &&
                    ` ${singleData.address},
                    ${singleData.pointedLocation},
                    ${singleData.state},
                    ${singleData.country},
                    ${singleData.pin}`}
                </p>
                <h5>{singleData && moment(singleData.date).format("LL")}</h5>
              </div>
              <div className="left_full_bottom_right">
                <div className="left_full_bottom_right_rr">
                  <h4>Description</h4>
                  <p>{singleData && singleData.description}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="right_full">
            <div className="right_full_top">
              <div className="right_full_top_upper">
                <h1>Request a Query</h1>
                <small>Send an attation to the provider</small>
              </div>
              <div className="right_full_top_lower">
                <button onClick={() => handleQuery()}>Send Me A Query</button>
              </div>
            </div>
            {sendQuery && (
              <div className="query_modal">
                <div className="query_modal_box">
                  <div
                    className="d-flex justify-content-between"
                    style={{ fontSize: "2rem" }}
                  >
                    <p>Looktaza</p>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => setSendQuery(false)}
                    >
                      <FaWindowClose />
                    </div>
                  </div>
                  <form action="">
                    {/* <label htmlFor="send_query">Name</label> */}
                    <input
                      placeholder="Name"
                      type="text"
                      id="send_query"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    {/* <label htmlFor="send_email">Email</label> */}
                    <input
                      placeholder="Email"
                      type="email"
                      id="send_email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {/* <label htmlFor="send_num">Phone no.</label> */}
                    <input
                      placeholder="Number"
                      type="number"
                      id="send_num"
                      value={phoneNo}
                      onChange={(e) => setPhoneNo(e.target.value)}
                    />
                    {/* <button onClick={(e) => handleSubmit(e)}>Send Query</button> */}
                    <button
                      type="submit"
                      onClick={(e) => handleSubmit(e)}
                      style={{
                        background: "#27005d",
                        border: "none",
                        overflow: "hidden",
                        height: "45px",
                      }}
                    >
                      {loading ? (
                        <div
                          style={{
                            position: "relative",
                            left: "40%",
                            width: "100px",
                            zIndex: "999",
                            top: "-35%",
                            // height: "45px",
                          }}
                        >
                          <lottie-player
                            // className="wow animate__animated animate__backInRight"
                            src="https://lottie.host/96c1b886-87c6-4cc5-999e-4360320ecf2a/0oTQR8dtOK.json"
                            background="transparent"
                            speed=".5"
                            // style="height: 60%;"
                            style={{ height: "30%" }}
                            loop
                            autoplay
                          ></lottie-player>
                        </div>
                      ) : (
                        "Send Query"
                      )}
                    </button>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FullDetails;
