import React, { useEffect, useState } from "react";
import Header from "../components/Header";
// import Home from "../components/Home";
import { Outlet } from "react-router-dom";
import { useCookies } from "react-cookie";
import Footer from "../components/Footer";

const UserLayout = () => {
  const [cookies, removeCookie] = useCookies(["user"]);
  const [user, setUser] = useState(false);
  const [userName, setUserName] = useState("");
  const [isServiceProvider, setIsServiceProvider] = useState(false);

  const handleLogout = () => {
    removeCookie("user");
    removeCookie("IsServiceProvider");
    removeCookie("secret");
    removeCookie("userId");
    removeCookie("userName");
  };

  // console.log(isServiceProvider, user);
  useEffect(() => {
    if (cookies?.user && cookies?.user !== "undefined") {
      setUser(true);
      setUserName(cookies?.userName);
    } else {
      setUser(false);
    }

    if (cookies?.IsServiceProvider == true) {
      setIsServiceProvider(true);
    } else {
      setIsServiceProvider(false);
    }
  }, [handleLogout]);
  return (
    <div>
      <Header
        showShignupbtn
        user={user}
        isServiceProvider={isServiceProvider}
        handleLogout={handleLogout}
        userName={userName}
      />
      <div>
        {/* <div>sidebar</div> */}
        <Outlet />
      </div>
      {/* <Home /> */}
      <Footer userName={userName} />
    </div>
  );
};

export default UserLayout;
