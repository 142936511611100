import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import serviceName from "../configs/staticData";
import { toast } from "react-toastify";
import { endPoints } from "../configs/AppConfig";
import axios from "axios";

function MyEditModal({ show, handleClose, allData, fetchRelatedData }) {
  //   const [show, setShow] = useState(false);

  //   const handleClose = () => setShow(false);
  //   const handleShow = () => setShow(true);

  const [webpImage, setWebpImage] = useState(
    allData[0] && JSON.parse(allData[0]?.image && allData[0].image)
  );

  const [inputData, setInputData] = useState({
    service: allData[0]?.serviceName ? allData[0].serviceName : "",
    phone: allData[0]?.phoneNo ? allData[0].phoneNo : "",
    address: allData[0]?.address ? allData[0].address : "",
    country: allData[0]?.country ? allData[0].country : "",
    stateName: allData[0]?.state ? allData[0].state : "",
    pin: allData[0]?.pin ? allData[0].pin : "",
    showPhone: allData[0]?.showPhone ? allData[0].showPhone : "",
    targetLocation: allData[0]?.pointedLocation
      ? allData[0].pointedLocation
      : "",
    description: allData[0]?.description ? allData[0].description : "",
  });

  const handleImage = async (event) => {
    // if (event.target.files && event.target.files[0]) {
    //   setShowImage(URL.createObjectURL(event.target.files[0]));
    // }

    if (event.target.files && event.target.files[0]) {
      let src = URL.createObjectURL(event.target.files[0]);

      let canvas = document.createElement("canvas");
      let context = canvas.getContext("2d");

      let imageDimension = await createImageBitmap(event.target.files[0]);

      let userImage = new Image();
      userImage.src = src;

      userImage.onload = function () {
        canvas.width = imageDimension.width;
        canvas.height = imageDimension.height;

        context.drawImage(userImage, 0, 0);

        let getWebPImage = canvas.toDataURL("image/webp");
        setWebpImage([...webpImage, getWebPImage]);
      };
    }

    //   let result = await new Promise((resolve, reject) => {
    //     canvas.toBlob((blob) => {
    //         if (blob != null) {
    //             resolve(blob);
    //         } else {
    //             reject(new Error("Failed to convert file"));
    //         }
    //     }, type, 1);
    // });

    // userImage.close();
    // return result;
  };

  //   console.log(
  //     serviceName?.country?.filter((cName) =>
  //       cName.countryName.toLocaleLowerCase()?.includes(inputData?.country)
  //     )[0] &&
  //       serviceName?.country?.filter((cName) =>
  //         cName.countryName.toLocaleLowerCase()?.includes(inputData?.country)
  //       )
  //   );

  const handleImageRemove = (imgFile) => {
    // console.log("hoiii");
    const filterImg = webpImage.filter((file) => file !== imgFile);
    setWebpImage(filterImg);
  };
  const handelCountry = (e) => {
    setInputData({ ...inputData, country: e.target.value });
  };
  const handelState = (e) => {
    setInputData({ ...inputData, stateName: e.target.value });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (
      !inputData.service ||
      !inputData.phone ||
      !inputData.address ||
      !inputData.pin ||
      !inputData.targetLocation ||
      !inputData.country ||
      !inputData.stateName
    ) {
      toast.warn("All fields are required");
    } else {
      const url = endPoints().updateUrl;
      const fData = new FormData();
      fData.append("serviceName", inputData.service.toLocaleLowerCase());
      fData.append("phoneNumber", inputData.phone);
      fData.append("address", inputData.address.toLocaleLowerCase());
      fData.append("pin", inputData.pin);
      fData.append("showPhone", inputData.showPhone);
      fData.append("description", inputData.description);
      fData.append(
        "targetLocation",
        inputData.targetLocation.toLocaleLowerCase()
      );
      fData.append("webpImage", JSON.stringify(webpImage));
      fData.append("countryName", inputData.country.toLocaleLowerCase());
      fData.append("stateName", inputData.stateName.toLocaleLowerCase());
      fData.append("userId", allData[0]?.id ? allData[0].id : "");

      await axios
        .post(url, fData)
        .then((res) => {
          setInputData({
            serviceName: "",
            phoneNumber: "",
            address: "",
            pin: "",
            targetLocation: "",
            description: "",
            country: "",
            stateName: "",
          });

          setWebpImage([]);

          if (res.data === "success") {
            toast.success("Update successfully");
            handleClose();
            fetchRelatedData();
          }
        })
        .catch((err) => toast.error(err));
    }
  };

  useEffect(() => {
    setInputData({
      service: allData[0]?.serviceName ? allData[0].serviceName : "",
      phone: allData[0]?.phoneNo ? allData[0].phoneNo : "",
      address: allData[0]?.address ? allData[0].address : "",
      country: allData[0]?.country ? allData[0].country : "",
      stateName: allData[0]?.state ? allData[0].state : "",
      pin: allData[0]?.pin ? allData[0].pin : "",
      showPhone: allData[0]?.showPhone ? allData[0].showPhone : "",
      targetLocation: allData[0]?.pointedLocation
        ? allData[0].pointedLocation
        : "",
      description: allData[0]?.description ? allData[0].description : "",
    });
    setWebpImage(
      allData[0] && JSON.parse(allData[0]?.image && allData[0].image)
    );
  }, [allData]);

  return (
    <>
      {/* <Button variant="primary">Launch demo modal</Button> */}

      <Modal
        aria-labelledby="example-modal-sizes-title-lg"
        show={show}
        onHide={handleClose}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <form>
            <div className="row mb-4">
              <div className="col">
                <div className="form-outline">
                  <input
                    type="text"
                    id="form3Example15"
                    className="form-control"
                    value={inputData.service}
                    onChange={(e) =>
                      setInputData({
                        ...inputData,
                        service: e.target.value,
                      })
                    }
                  />
                  <label className="form-label" htmlFor="form3Example15">
                    Service
                  </label>
                </div>
              </div>
              <div className="col">
                <div className="form-outline">
                  <input
                    type="text"
                    id="form3Example26"
                    className="form-control"
                    value={inputData.targetLocation}
                    onChange={(e) =>
                      setInputData({
                        ...inputData,
                        targetLocation: e.target.value,
                      })
                    }
                  />
                  <label className="form-label" htmlFor="form3Example26">
                    Target location
                  </label>
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col">
                <div className="form-outline">
                  <select
                    name="Choose"
                    id="form3Example12"
                    className=" mt-2 form-control"
                    onChange={(e) => handelCountry(e)}
                    value={inputData.country}
                  >
                    {/* <option value={inputData.country} selected>
                      {inputData.country}
                    </option> */}
                    {serviceName.country.map((sName, index) => {
                      return (
                        <option
                          key={index}
                          value={sName.countryName}
                          //   onChange={(e) => handelCountry(e)}
                        >
                          {sName.countryName}
                        </option>
                      );
                    })}
                  </select>
                  <label className="form-label" htmlFor="form3Example12">
                    Country
                  </label>
                  {/* <input
                    type="text"
                    id="form3Example12"
                    className="form-control"
                    value={inputData.country}
                    onChange={(e) =>
                      setInputData({
                        ...inputData,
                        country: e.target.value,
                      })
                    }
                  />
                  <label className="form-label" htmlFor="form3Example12">
                    Country
                  </label> */}
                </div>
              </div>
              <div className="col">
                <div className="form-outline">
                  <select
                    name="Choose"
                    id="inputType"
                    className=" mt-2 form-control"
                    onChange={(e) => handelState(e)}
                    value={inputData.stateName}
                  >
                    {/* <option value="">{inputData.stateName}</option> */}
                    {inputData?.country &&
                      serviceName?.country
                        ?.filter((cName) =>
                          cName.countryName
                            .toLocaleLowerCase()
                            ?.includes(inputData?.country.toLocaleLowerCase())
                        )[0]
                        .state?.map((stName, index) => {
                          return (
                            <option
                              key={index}
                              value={stName}
                              onChange={handelCountry}
                            >
                              {stName}
                            </option>
                          );
                        })}
                  </select>
                  {/* <input
                    type="text"
                    id="form3Example23"
                    className="form-control"
                    value={inputData.stateName}
                    onChange={(e) =>
                      setInputData({
                        ...inputData,
                        stateName: e.target.value,
                      })
                    }
                  /> */}
                  <label className="form-label" htmlFor="form3Example23">
                    State
                  </label>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col">
                <div className="form-outline">
                  <input
                    type="text"
                    id="form3Example1"
                    className="form-control"
                    value={inputData.phone}
                    onChange={(e) =>
                      setInputData({
                        ...inputData,
                        phone: e.target.value,
                      })
                    }
                  />
                  <label className="form-label" htmlFor="form3Example1">
                    Phone number
                  </label>
                </div>
              </div>
              <div className="col">
                <div className="form-outline">
                  <input
                    type="text"
                    id="form3Example2"
                    className="form-control"
                    value={inputData.address}
                    onChange={(e) =>
                      setInputData({
                        ...inputData,
                        address: e.target.value,
                      })
                    }
                  />
                  <label className="form-label" htmlFor="form3Example2">
                    Address
                  </label>
                </div>
              </div>
              <div className="col">
                <div className="form-outline">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    value={inputData.showPhone}
                    onChange={(e) =>
                      setInputData({
                        ...inputData,
                        showPhone: e.target.value,
                      })
                    }
                  >
                    <option value={inputData.showPhone} selected>
                      {inputData.showPhone}
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                  <label className="form-label" htmlFor="form3Example2">
                    Want to show phone?
                  </label>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-6">
                <div className="form-outline">
                  <input
                    type="text"
                    id="form3Example2"
                    className="form-control"
                    value={inputData.pin}
                    onChange={(e) =>
                      setInputData({
                        ...inputData,
                        pin: e.target.value,
                      })
                    }
                  />
                  <label className="form-label" htmlFor="form3Example2">
                    Pin
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className="form-outline">
                  <div className="form-group  mt-3">
                    <label htmlhtmlFor="inputAddress">Add image</label>
                    <input
                      type="file"
                      className="form-control"
                      accept="image/*"
                      multiple
                      onChange={handleImage}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="uploaded_image">
              {/* <img src={showImage} alt="" /> */}
              {/* <img src={webpImage} alt="" /> */}
              {webpImage?.map((imgFile, index) => {
                return (
                  <div key={index} className="uploaded_image_box">
                    <img src={imgFile} alt="Loading" loading="lazy" />
                    <div
                      onClick={() => handleImageRemove(imgFile)}
                      className="remove_btn"
                    >
                      Remove
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="mb-3">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="form-label"
              >
                Description
              </label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                value={inputData.description}
                onChange={(e) =>
                  setInputData({
                    ...inputData,
                    description: e.target.value,
                  })
                }
              ></textarea>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => handleUpdate(e)}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MyEditModal;
